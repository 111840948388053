/******************************************************************
 * MailTemplateType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const MailTemplateTypes = [
    "none",
    "verificationCode",
    "userVerified",
    "subscriptionExpired",
    "subscriptionPaymentFailed"
] as const

export type MailTemplateType = typeof MailTemplateTypes[number]

export function isValidMailTemplateType(templateType: string): boolean {
    return MailTemplateTypes.includes(templateType as MailTemplateType)
}

import {EventOwnerType} from "../types/EventOwnerType";
import {TrackOwnerType} from "../types/TrackOwnerType";
import {FrontendRouteUtils} from "../utils/FrontendRouteUtils";

/******************************************************************
 * ClientRoute
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendRoute {

    /******************************************************************
     * PROPERTIES
     *****************************************************************/

    static readonly HOME = "/"

    /* ----------------------------------------------------------------
 	 * MODALS
 	 * --------------------------------------------------------------*/

    static readonly PADDLE = "/paddle"
    static readonly NEWSLETTER = "/newsletter"
    static readonly GROUPS = "/groups"
    static readonly EVENTS = "/events"
    static readonly PLANS = "/plans"
    static readonly ACADEMY = "/academy"
    static readonly IMPRINT = "/imprint"
    static readonly PRIVACY = "/privacy"
    static readonly TERMSOFUSE = "/termsofuse"
    static readonly ABOUT = "/about"
    static readonly ARTICLE = "/article"

    /* ----------------------------------------------------------------
 	 * USER
 	 * --------------------------------------------------------------*/

    static readonly USER_BASE = "/m"

    static USER(userPath: string) {
        return FrontendRoute.USER_BASE + userPath
    }

    static USER_ACHIEVEMENTS(userPath) {
        return FrontendRoute.USER_BASE + userPath + "/achievements"
    }

    static USER_DRIVINGSCHOOL(userPath) {
        return FrontendRoute.USER_BASE + userPath + "/drivingschool"
    }

    static USER_TRAINING(userPath) {
        return FrontendRoute.USER_BASE + userPath + "/training"
    }

    static USER_EVENTS(userPath) {
        return FrontendRoute.USER_BASE + userPath + "/events"
    }

    static USER_GROUPS(userPath) {
        return FrontendRoute.USER_BASE + userPath + "/groups"
    }

    static USER_TRACKS(userPath) {
        return FrontendRoute.USER_BASE + userPath + "/tracks"
    }

    static USER_GARAGE(userPath) {
        return FrontendRoute.USER_BASE + userPath + "/garage"
    }

    /* ----------------------------------------------------------------
 	 * EVENT
 	 * --------------------------------------------------------------*/

    static readonly EVENT_BASE = "/event"

    static EVENT(ownerType: EventOwnerType, ownerPath: string, eventPath: string) {
        return FrontendRoute.EVENT_BASE + "/" + FrontendRouteUtils.shortenOwnerType(ownerType) + ownerPath + eventPath
    }

    static EVENT_RESULT(ownerType: EventOwnerType, ownerPath, eventPath, resultPath) {
        return FrontendRoute.EVENT_BASE + "/" + FrontendRouteUtils.shortenOwnerType(ownerType) + ownerPath + eventPath + "/result" + resultPath
    }

    static EVENT_SESSION(ownerType: EventOwnerType, ownerPath, eventPath, sessionPath) {
        return FrontendRoute.EVENT_BASE + "/" + FrontendRouteUtils.shortenOwnerType(ownerType) + ownerPath + eventPath + "/session" + sessionPath
    }

    static EVENT_TRACK(ownerType: EventOwnerType, ownerPath, eventPath, trackPath) {
        return FrontendRoute.EVENT_BASE + "/" + FrontendRouteUtils.shortenOwnerType(ownerType) + ownerPath + eventPath + "/track" + trackPath
    }

    static EVENT_PARTICIPANTS(ownerType: EventOwnerType, ownerPath, eventPath) {
        return FrontendRoute.EVENT_BASE + "/" + FrontendRouteUtils.shortenOwnerType(ownerType) + ownerPath + eventPath + "/participants"
    }

    /* ----------------------------------------------------------------
 	 * GROUP
 	 * --------------------------------------------------------------*/

    static readonly GROUP_BASE = "/g"

    static GROUP(groupPath) {
        return FrontendRoute.GROUP_BASE + groupPath
    }

    static GROUP_EVENTS(groupPath) {
        return FrontendRoute.GROUP_BASE + groupPath + "/events"
    }

    static GROUP_TRACKS(groupPath) {
        return FrontendRoute.GROUP_BASE + groupPath + "/tracks"
    }

    static GROUP_MEMBERS(groupPath) {
        return FrontendRoute.GROUP_BASE + groupPath + "/members"
    }

    /* ----------------------------------------------------------------
 	 * TRACK
 	 * --------------------------------------------------------------*/

    static readonly TRACK_BASE = "/track"

    static TRACK(ownerType: TrackOwnerType, ownerPath, trackPath) {
        return FrontendRoute.TRACK_BASE + "/" + FrontendRouteUtils.shortenOwnerType(ownerType) + ownerPath + trackPath
    }

    static TRACK_FILTER(ownerType: TrackOwnerType, ownerPath, trackPath, filterPath) {
        return FrontendRoute.TRACK_BASE + "/" + FrontendRouteUtils.shortenOwnerType(ownerType) + ownerPath + trackPath + filterPath
    }

    /* ----------------------------------------------------------------
 	 * ADMIN
 	 * --------------------------------------------------------------*/

    static readonly ADMIN = "/admin"
    static readonly ADMIN_DICTIONARY = "/admin/dictionary"
    static readonly ADMIN_ARTICLES = "/admin/articles"
    static readonly ADMIN_USERS = "/admin/users"
    static readonly ADMIN_SIMULATOR = "/admin/simulator"
    static readonly ADMIN_MAILFILTER = "/admin/mailfilter"
    static readonly ADMIN_DATABASE = "/admin/database"
    static readonly ADMIN_LOGS = "/admin/logs"
    static readonly ADMIN_CACHES = "/admin/caches"
    static readonly ADMIN_ENVVARS = "/admin/envvars"
    static readonly ADMIN_MAILINGS = "/admin/mailings"
    static readonly ADMIN_MAILTEMPLATES = "/admin/mailtemplates"


}

import * as React from "react";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useRouteStates} from "../../../../hooks/useRouteStates";
import {useAuthUser} from "../../../../hooks/useAuthUser";

/******************************************************************
 * UserSidebarMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserSidebarMenu() {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const {dict, router, state} = useServices();
    const {authUserHasUnpaidSubscription} = useAuthUser()
    const {isRouteOwnerAuthUser, routeOwnerPath} = useRouteOwner();
    const {
        showUserHome,
        showUserDrivingSchool,
        showUserTraining,
        showUserEvents,
        showUserGroups,
        showUserTracks,
        showUserGarage,
        showUserAchievements
    } = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <nav className="user-sidebar-menu">
            <main>
                <LabelButton
                    label={dict("user.menu.home")}
                    style="sidebar"
                    selected={showUserHome}
                    onClick={() => router.showRoute(FrontendRoute.USER(routeOwnerPath))}/>
                <Divider style="sidebar-menu"/>
                <LabelButton
                    label={dict("user.menu.achievements")}
                    style="sidebar"
                    badgeColor={"yellow"}
                    badgeLabel={dict("generic.new")}
                    selected={showUserAchievements}
                    onClick={() => router.showRoute(FrontendRoute.USER_ACHIEVEMENTS(routeOwnerPath))}/>
                <Divider style="sidebar-menu"/>
                {isRouteOwnerAuthUser &&
                    <LabelButton
                        label={dict("user.menu.drivingSchool")}
                        style="sidebar"
                        selected={showUserDrivingSchool}
                        onClick={() => router.showRoute(FrontendRoute.USER_DRIVINGSCHOOL(routeOwnerPath))}/>}
                <LabelButton
                    label={dict("user.menu.training")}
                    style="sidebar"
                    selected={showUserTraining}
                    icon={isRouteOwnerAuthUser && showUserTraining ? "add" : null}
                    iconScale={0.8}
                    onClick={() => router.showRoute(FrontendRoute.USER_TRAINING(routeOwnerPath))}
                    onIconClick={isRouteOwnerAuthUser && showUserTraining && (() => state.showCreateEvent.setValue(true))}/>
                <LabelButton
                    label={dict("user.menu.tracks")}
                    style="sidebar"
                    icon={isRouteOwnerAuthUser && showUserTracks ? "add" : null}
                    iconScale={0.8}
                    selected={showUserTracks}
                    onClick={() => router.showRoute(FrontendRoute.USER_TRACKS(routeOwnerPath))}
                    onIconClick={isRouteOwnerAuthUser && showUserTracks && (() => state.showCreateTrack.setValue(true))}/>
                <LabelButton
                    label={dict("user.menu.garage")}
                    style="sidebar"
                    icon={isRouteOwnerAuthUser && showUserGarage ? "add" : null}
                    iconScale={0.8}
                    selected={showUserGarage}
                    onClick={() => router.showRoute(FrontendRoute.USER_GARAGE(routeOwnerPath))}
                    onIconClick={isRouteOwnerAuthUser && showUserGarage && (() => state.showCar.setValue(true))}/>
                <Divider style="sidebar-menu"/>
                <LabelButton
                    label={dict("user.menu.events")}
                    style="sidebar"
                    selected={showUserEvents}
                    onClick={() => router.showRoute(FrontendRoute.USER_EVENTS(routeOwnerPath))}/>
                <LabelButton
                    label={dict("user.menu.groups")}
                    style="sidebar"
                    icon={isRouteOwnerAuthUser && showUserGroups ? "add" : null}
                    iconScale={0.8}
                    selected={showUserGroups}
                    onClick={() => router.showRoute(FrontendRoute.USER_GROUPS(routeOwnerPath))}
                    onIconClick={isRouteOwnerAuthUser && showUserGroups && (() => state.showCreateGroup.setValue(true))}/>
                {isRouteOwnerAuthUser && <>
                    <Divider style="sidebar-menu"/>
                    <LabelButton
                        label={dict("user.menu.settings")}
                        style="sidebar"
                        badgeLabel={authUserHasUnpaidSubscription ? dict("plan.badge.paynow") : null}
                        badgeColor={"red"}
                        onClick={() => state.showAuthUserSettings.setValue("default")}/>
                </>}
            </main>
        </nav>
    );

}

import {useEffect, useState} from "react";
import {useSessionState} from "../../hooks/useSessionState";
import {ISessionLeaderboardEntry} from "../../../../shared/types/ISessionLeaderboardEntry";
import {useServices} from "../../hooks/useServices";
import {LeaderboardUtils} from "../../../../shared/utils/LeaderboardUtils";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {TimeUtils} from "../../../../shared/utils/TimeUtils";
import {FrontendConfig} from "../../../core/FrontendConfig";
import {useDelayedInterval} from "../../hooks/useDelayedInterval";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";

/******************************************************************
 * ReactComponent
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useDriverAnnouncer(props: {
    visibleSettings: StateValue<boolean>[]
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {speaker, time, language} = useServices()
    const {
        sessionLeaderboard,
        sessionState,
        sessionLaps,
        sessionDuration,
        sessionClassificationMode,
        sessionAnnounceLapsRemainingEveryLap,
        sessionAnnounceTimeRemainingEverySeconds,
        sessionAnnounceDrivenTimeEveryLap,
        sessionFinishType
    } = useSessionState()
    const {addDelayedInterval, removeDelayedInterval, removeAllIntervals} = useDelayedInterval()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [previousAnnounceables, setPreviousAnnounceables] = useState<ISessionLeaderboardEntry[]>()
    const [announceables, setCurrentAnnounceables] = useState<ISessionLeaderboardEntry[]>()
    const [currentLeaderboard, setCurrentLeaderboard] = useState<ISessionLeaderboardEntry[]>()
    const [previousLeaderboard, setPreviousLeaderboard] = useState<ISessionLeaderboardEntry[]>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        const leaderboardEntries = sessionClassificationMode == "teams"
            ? sessionLeaderboard?.flatMap(entry => entry.team?.leaderboardEntries)
            : sessionLeaderboard?.map(entry => entry)
        setPreviousAnnounceables(announceables?.map(entry => entry))
        setPreviousLeaderboard(currentLeaderboard?.map(entry => entry))
        setCurrentLeaderboard(leaderboardEntries)
        setCurrentAnnounceables(filterAnnounceables(leaderboardEntries))
    }, [sessionLeaderboard]);

    useEffect(() => {
        if (sessionState !== "running") return
        if (!previousAnnounceables) return

        announceDriverLatestLapTime()
        announceDriverNewBestLapTime()
        announceDriverDrivenTime()
        announceDriverAlreadyDrivenTime()
        announceDriverNewBestDrivenTime()
        announceDriverStintDrivenLaps()
        announceDriverOverallDrivenLaps()
        announceDriverPosition()
        announceDriverGapToNext()
        announceDriverGapToLeader()
        announceDriverLapsRemaining()
        announceDriverTimeRemaining()
        announceDriverTarget()
        announceDriverTotalScore()
        announceDriverNewBestScore()
    }, [announceables])

    useEffect(() => {
        if (sessionState !== "running") return
        if (!previousAnnounceables) return
        removeDriverAnnounceTimeInterval()
        announceDriverGapToFollower()
    }, [currentLeaderboard])

    useEffect(() => {
        if (sessionState !== "running") return
        removeAllIntervals()
    }, [sessionState])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function isSettingVisible(annouceSettingState: StateValue<boolean>) {
        return props.visibleSettings?.some(setting => setting === annouceSettingState)
    }

    function announceDriverLatestLapTime() {
        announceables?.forEach(entry => {
            if (!isNewLapForAnnounceables(entry)) return
            if (!isSettingVisible(speaker.driver.lapTime)) return
            const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
            speaker.driver.announceLapTime(nick, entry.latestStint.lastLapTime)
        })
    }

    function announceDriverNewBestLapTime() {
        announceables?.forEach(entry => {
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
            if (entry.overall.bestLapTime < prevEntry?.overall?.bestLapTime) {
                if (!isSettingVisible(speaker.driver.newBestLapTime)) return
                const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
                speaker.driver.announceNewBestLapTime(nick, entry.overall.bestLapTime)
            }
        })
    }

    function announceDriverDrivenTime() {
        announceables?.forEach(entry => {
            if (!isSettingVisible(speaker.driver.drivenTime)) return
            if (!hasFinished(entry)) return
            if (!isLatestStintTheSameAsPrevious(entry)) return
            const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
            speaker.driver.announceDrivenTime(nick, entry.latestStint.drivenTime)
        })
    }

    function announceDriverNewBestDrivenTime() {
        announceables?.forEach(entry => {
            if (!isSettingVisible(speaker.driver.newBestDrivenTime)) return
            if (!hasFinished(entry)) return
            if (!isLatestStintTheSameAsPrevious(entry)) return
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
            if (!entry.bestDrivenTimeStint?.drivenLaps) return
            let isBestTime = entry.bestDrivenTimeStint.drivenLaps > prevEntry?.bestDrivenTimeStint?.drivenLaps
                || (entry.bestDrivenTimeStint.drivenLaps == prevEntry?.bestDrivenTimeStint?.drivenLaps
                    && entry.bestDrivenTimeStint.drivenTime < prevEntry?.bestDrivenTimeStint?.drivenTime)
            if (isBestTime) {
                const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
                speaker.driver.announceNewBestDrivenTime(nick, entry.bestDrivenTimeStint.drivenTime)
            }
        })
    }

    function announceDriverStintDrivenLaps() {
        announceables?.forEach(entry => {
            if (entry.state === "finished") return
            if (!isSettingVisible(speaker.driver.stintDrivenLaps)) return
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
            if (entry.latestStint.drivenLaps > 0
                && entry.latestStint.drivenLaps !== prevEntry?.latestStint.drivenLaps) {
                const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
                speaker.driver.announceStintDrivenLaps(nick, entry.latestStint.drivenLaps)
            }
        })
    }

    function announceDriverOverallDrivenLaps() {
        announceables?.forEach(entry => {
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
            if (entry.overall.drivenLaps !== prevEntry?.overall.drivenLaps) {
                if (!isSettingVisible(speaker.driver.overallDrivenLaps)) return
                const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
                speaker.driver.announceOverallDrivenLaps(nick, entry.overall.drivenLaps)
            }
        })
    }

    function announceDriverPosition() {
        announceables?.forEach(entry => {
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
            if (entry.position !== prevEntry?.position) {
                if (!isSettingVisible(speaker.driver.position)) return
                const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
                speaker.driver.announcePosition(nick, entry.position)
            }
        })
    }

    function announceDriverGapToLeader() {
        announceables?.forEach(entry => {
            if (!isSettingVisible(speaker.driver.gapToLeader)) return
            if (!isNewLapForAnnounceables(entry)) return
            if (entry.position === 1) return
            const leaderEntry = currentLeaderboard[0]
            if (!leaderEntry) return
            const gap = LeaderboardUtils.timeGapToHeadingEntry(entry, leaderEntry)
            if (gap === null) return
            const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
            const leaderNick = SpeakerUtils.nickToSpeak(leaderEntry.latestStint.user, language)
            speaker.driver.announceGapToLeader(nick, gap, leaderNick)
        })
    }

    function announceDriverGapToNext() {
        announceables?.forEach(entry => {
            if (!isSettingVisible(speaker.driver.gapToNext)) return
            if (!isNewLapForAnnounceables(entry)) return
            if (entry.position === 1) return
            const nextDriverEntry = currentLeaderboard.find(leaderboardEntry => {
                return leaderboardEntry.position === entry.position - 1
            })
            if (!nextDriverEntry) return
            const gap = LeaderboardUtils.timeGapToHeadingEntry(entry, nextDriverEntry)
            if (gap === null) return
            const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
            const nextDriverNick = SpeakerUtils.nickToSpeak(nextDriverEntry.latestStint.user, language)
            speaker.driver.announceGapToNext(nick, gap, nextDriverNick)
        })
    }

    function announceDriverGapToFollower() {
        currentLeaderboard?.forEach(entry => {
            if (!isSettingVisible(speaker.driver.gapToFollower)) return
            if (!isNewLapForLeaderboard(entry)) return
            const nextEntry = currentLeaderboard.find(leaderboardEntry => {
                return leaderboardEntry.position === entry.position - 1
            })
            if (!nextEntry) return
            const isAnnounceable = announceables?.find(announceable => {
                return announceable.latestStint.user._id === nextEntry.latestStint.user._id
            })
            if (!isAnnounceable) return
            const gap = LeaderboardUtils.timeGapToHeadingEntry(entry, nextEntry)
            if (gap === null) return
            const followerNick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
            const nick = SpeakerUtils.nickToSpeak(nextEntry.latestStint.user, language)
            speaker.driver.announceGapToFollower(nick, gap, followerNick)
        })
    }

    function announceDriverLapsRemaining() {
        if (sessionFinishType !== "laps") return
        announceables?.forEach(entry => {
            if (!isNewLapForAnnounceables(entry)) return
            if (!isSettingVisible(speaker.driver.lapsRemaining)) return
            const lapsRemaining = sessionLaps - entry.latestStint.drivenLaps
            if (lapsRemaining <= 0) return
            if (lapsRemaining % sessionAnnounceLapsRemainingEveryLap === 0
                || lapsRemaining === 1) {
                const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
                speaker.driver.announceLapsRemaining(nick, lapsRemaining)
            }
        })
    }

    function announceDriverAlreadyDrivenTime() {
        announceables?.forEach(entry => {
            if (!isNewLapForAnnounceables(entry)) return
            if (!isSettingVisible(speaker.driver.alreadyDrivenTime)) return
            const drivenLaps = entry.latestStint.drivenLaps
            if (drivenLaps === 0) return
            if (drivenLaps % sessionAnnounceDrivenTimeEveryLap === 0) {
                const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
                speaker.driver.announceAlreadyDrivenTime(nick, entry.latestStint.drivenTime, drivenLaps)
            }
        })
    }

    function announceDriverTimeRemaining() {
        if (sessionFinishType !== "duration") return
        announceables?.forEach(entry => {
            removeDelayedInterval(entry.latestStint.user._id)
            if (entry.state !== "driving") return
            const remainingSeconds = Math.round(TimeUtils.getRemainingSeconds(
                entry.latestStint.signalTimestamp,
                sessionDuration,
                time.date
            ))
            let delayInSeconds = remainingSeconds % sessionAnnounceTimeRemainingEverySeconds
            let delayOffset = (delayInSeconds == 0 && (entry.latestStint.drivenTime ?? 0) < sessionAnnounceTimeRemainingEverySeconds)
                ? sessionAnnounceTimeRemainingEverySeconds
                : 0
            if (remainingSeconds <= sessionAnnounceTimeRemainingEverySeconds) {
                delayInSeconds = remainingSeconds - FrontendConfig.ANNOUNCE_TIME_REMAINING_MIN_SECONDS
                delayOffset = 0
            }
            if (remainingSeconds <= FrontendConfig.ANNOUNCE_TIME_REMAINING_MIN_SECONDS) {
                delayInSeconds = remainingSeconds
                delayOffset = 0
            }
            if (remainingSeconds <= 0) return
            addDelayedInterval({
                key: entry.latestStint.user._id,
                delayInSeconds: delayInSeconds + delayOffset,
                callback: () => {
                    let remainingSeconds = Math.round(TimeUtils.getRemainingSeconds(
                        entry.latestStint.signalTimestamp,
                        sessionDuration,
                        time.date
                    ))
                    if (isSettingVisible(speaker.driver.timeRemaining)) {
                        const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
                        speaker.driver.announceTimeRemaining(nick, remainingSeconds)
                    }
                    removeDelayedInterval(entry.latestStint.user._id)
                    announceDriverTimeRemaining()
                }
            })
        })
    }

    function announceDriverTotalScore() {
        announceables?.forEach(entry => {
            if (!hasFinished(entry)) return
            if (!isLatestStintTheSameAsPrevious(entry)) return
            if (!isSettingVisible(speaker.driver.totalScore)) return
            const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
            speaker.driver.announceTotalScore(nick, entry.latestStint.score)
        })
    }

    function announceDriverNewBestScore() {
        announceables?.forEach(entry => {
            if (!hasFinished(entry)) return
            if (!isLatestStintTheSameAsPrevious(entry)) return
            if (!isSettingVisible(speaker.driver.newBestScore)) return
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
            if (entry.latestStint.score < prevEntry?.bestGymkhanaStint?.score) return
            const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
            speaker.driver.announceNewBestScore(nick, entry.latestStint.score)
        })
    }

    function announceDriverTarget() {
        announceables?.forEach(entry => {
            if (!isNewTarget(entry)) return
            if (!isSettingVisible(speaker.driver.targetScore)
                && !isSettingVisible(speaker.driver.targetName)) return
            const nick = SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
            speaker.driver.announceTarget(nick, entry.latestStint.lastTarget)
        })
    }

    function removeDriverAnnounceTimeInterval() {
        currentLeaderboard?.forEach(entry => {
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousLeaderboard, entry.latestStint._id)
            const prevAnnounceableEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
            const currentAnnounceableEntry = LeaderboardUtils.findEntryByStintID(announceables, entry.latestStint._id)
            const hasFinished = entry.state === "finished" && prevEntry?.state !== "finished"
            const hasBeenRemovedFromAnnounceables = prevAnnounceableEntry && !currentAnnounceableEntry
            if (hasFinished || hasBeenRemovedFromAnnounceables) {
                removeDelayedInterval(entry.latestStint.user._id)
            }
        })
    }

    function filterAnnounceables(leaderboard: ISessionLeaderboardEntry[]): ISessionLeaderboardEntry[] {
        return leaderboard?.filter(entry => {
            if (!entry) return false
            const prevEntry = previousAnnounceables
                ? LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
                : null
            return SpeakerUtils.areUserAnnouncementsEnabled(entry.latestStint?.user._id)
                && (prevEntry?.state !== "finished" ?? true)
        })
    }

    function isNewLapForAnnounceables(entry: ISessionLeaderboardEntry) {
        const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
        return entry.overall.drivenLaps > prevEntry?.overall?.drivenLaps
    }

    function isNewLapForLeaderboard(entry: ISessionLeaderboardEntry) {
        const prevEntry = LeaderboardUtils.findEntryByStintID(previousLeaderboard, entry.latestStint._id)
        return entry.overall.drivenLaps > prevEntry?.overall?.drivenLaps
    }

    function hasFinished(entry: ISessionLeaderboardEntry) {
        const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
        if (!prevEntry) return false
        return entry.state === "finished" && prevEntry.state !== "finished"
    }

    function isNewTarget(entry: ISessionLeaderboardEntry) {
        const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
        if (!prevEntry && entry.latestStint?.targetHits > 1) {
            return false
        }
        return (entry.latestStint?.targetHits ?? 0) > (prevEntry?.latestStint?.targetHits ?? 0)
    }

    function isLatestStintTheSameAsPrevious(entry: ISessionLeaderboardEntry): boolean {
        const prevEntry = LeaderboardUtils.findEntryByStintID(previousAnnounceables, entry.latestStint._id)
        if (!prevEntry) return false
        return prevEntry.latestStint._id == entry.latestStint._id
    }

}

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import JSConfetti from "js-confetti";

/******************************************************************
 * UpgradeConfetti
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UpgradeConfetti() {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const jsConfettiRef = useRef<JSConfetti>()
    const canvasRef = useRef<HTMLCanvasElement>()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [state, setState] = useState<"play" | "init">("init")

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!canvasRef.current || jsConfettiRef.current) return
        jsConfettiRef.current = new JSConfetti({
            canvas: canvasRef.current,
        })
        setState("play")
    }, [canvasRef.current])

    useEffect(() => {
        if (!jsConfettiRef.current) return
        if (state != "play") return
        setTimeout(() => {
            jsConfettiRef.current.addConfetti({
                confettiRadius: 3,
                confettiNumber: 600,
                confettiColors: ["#e09c07"],
            })
        }, 1000)
    }, [jsConfettiRef.current || state])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <canvas
            ref={canvasRef}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                userSelect: "none",
                pointerEvents: "none",
                width: "100%",
                height: "100%",
            }}/>
    </>

}

import * as React from "react";
import {useEffect, useState} from "react";
import {LeaderboardEntryContextMenu} from "../../../context/leaderboard/LeaderboardEntryContextMenu";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {Icon} from "../../../icons/Icon";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {SpeakerUtils} from "../../../../../utils/SpeakerUtils";
import {ApprovalStateIcon} from "../../../info/approval/ApprovalStateIcon";
import {useSessionState} from "../../../../hooks/useSessionState";

/******************************************************************
 * TableLeaderboardEntryMenuCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardEntryMenuCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {sessionIsFinished} = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showSpeakerIcon, setShowSpeakerIcon] = useState<boolean>(false)
    const [userLocalStorageKey, setUserLocalStorageKey] = useState(userAnnouncementLocalStorageKey());
    const [teamLocalStorageKey, setTeamLocalStorageKey] = useState(teamAnnouncementLocalStorageKey());

    const [userAnnouncementsState] = useLocalStorage(userLocalStorageKey)
    const [teamAnnouncementsState] = useLocalStorage(teamLocalStorageKey)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setUserLocalStorageKey(userAnnouncementLocalStorageKey())
        setTeamLocalStorageKey(teamAnnouncementLocalStorageKey())
        if (props.entry?.team) {
            setShowSpeakerIcon(SpeakerUtils.areTeamAnnouncementsEnabled(teamName()))
        } else {
            setShowSpeakerIcon(SpeakerUtils.areUserAnnouncementsEnabled(userID()))
        }
    }, [props.entry])

    useEffect(() => {
        if (props.entry?.team) {
            setShowSpeakerIcon(SpeakerUtils.areTeamAnnouncementsEnabled(teamName()))
        } else {
            setShowSpeakerIcon(SpeakerUtils.areUserAnnouncementsEnabled(userID()))
        }
    }, [userAnnouncementsState, teamAnnouncementsState])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showContextMenu(): boolean {
        if (props.entry.isStartOrder
            || props.entry.team) {
            return false
        }
        return true
    }

    function userID(): MongoObjectIDType {
        if (props.entry.team) return null
        return props.entry.latestStint?.user?._id
    }

    function teamName(): string {
        if (!props.entry.team) return null
        return props.entry.team.name
    }

    function userAnnouncementLocalStorageKey(): string {
        return SpeakerUtils.getUserAnnouncementLocalStorageKey(userID())
    }

    function teamAnnouncementLocalStorageKey(): string {
        return SpeakerUtils.getTeamAnnouncementLocalStorageKey(teamName())
    }

    function showIcons(): boolean {
        return showSpeakerIcon
            || props.entry.hasVideos
            || props.entry.hasRaceDirector
            || props.entry.hasPendingStints
            || props.entry.hasOnlyRejectedStints
            || props.entry.hasApprovedStints
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-menu-cell"
            data-is-startorder={props.entry.isStartOrder}>
            {showContextMenu() &&
                <LeaderboardEntryContextMenu
                    entry={props.entry}/>}
            {showIcons() &&
                <div className="table-leaderboard-menu-cell-icons">
                    {showSpeakerIcon && !sessionIsFinished &&
                        <Icon
                            type="speaker"
                            scale={0.6}/>}
                    {props.entry.hasVideos &&
                        <Icon
                            type="youtube"/>}
                    {props.entry.hasRaceDirector &&
                        <Icon
                            type="warning"
                            scale={0.9}/>}
                    {props.entry.hasOnlyRejectedStints &&
                        <ApprovalStateIcon
                            state="rejected"/>}
                    {props.entry.hasApprovedStints &&
                        <ApprovalStateIcon
                            state="approved"/>}
                    {props.entry.hasPendingStints && <>
                        <ApprovalStateIcon
                            state="pending"
                            scale={0.95}
                            hasVideo={props.entry.hasPendingStintWithVideo}/>
                    </>}
                </div>}
        </div>
    );

}

import * as React from "react";
import {useEffect, useState} from "react";
import {DriftClubLogo} from "../../logo/DriftClubLogo";
import {SocketIndicator} from "../../ui/utils/socket/SocketIndicator";
import {AuthUserMenu} from "./authusermenu/AuthUserMenu";
import {HomeMenu} from "./homemenu/HomeMenu";
import {useAuthUser} from "../../hooks/useAuthUser";
import {useRouteStates} from "../../hooks/useRouteStates";
import {useServices} from "../../hooks/useServices";
import {FrontendRoute} from "../../../../shared/routes/FrontendRoute";
import {MetaBarMenu} from "./metabarmenu/MetaBarMenu";
import {Divider} from "../../ui/utils/divider/Divider";
import {IconButton} from "../../ui/buttons/icon/IconButton";
import {Banderole} from "../../ui/info/banderole/Banderole";
import {FrontendConfig} from "../../../core/FrontendConfig";

/******************************************************************
 * MetaBarSystem
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MetaBarSystem() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {authUserExists, authUserIsAdmin} = useAuthUser();
    const {showSideBar, showAdmin} = useRouteStates();
    const {router, state, dict, api} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [hasPublicLiveEvents, setHasPublicLiveEvents] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        requestLiveState()
        state.hasLiveEvents.onChangeSignal.add(socketUpdate)
        state.showEventsFinder.onChangeSignal.add(requestLiveState)
        return () => {
            state.hasLiveEvents.onChangeSignal.remove(socketUpdate)
            state.showEventsFinder.onChangeSignal.remove(requestLiveState)
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function socketUpdate() {
        setHasPublicLiveEvents(state.hasLiveEvents.getValue())
    }

    async function requestLiveState() {
        if (state.showEventsFinder.getValue()) return
        const hasLiveEvents = await api.event.arePublicEventsLive()
        setHasPublicLiveEvents(hasLiveEvents)
    }

    function isNextEnvironment() {
        return FrontendConfig.SERVER_ENVIRONMENT == "next"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <aside
            className="metabar-system"
            data-minimized={showSideBar}>
            <Banderole
                label={isNextEnvironment() ? "NEXT" : "BETA"}
                color={isNextEnvironment() ? "red" : "yellow"}
                position="global"/>
            <DriftClubLogo
                style={showSideBar ? "icon-only-with-info" : "full"}
                onClick={() => router.showRoute(FrontendRoute.HOME)}/>
            <SocketIndicator/>
            <div className="metabar-system-navs">
                {showSideBar
                    ? <>
                        {authUserExists &&
                            <AuthUserMenu/>}
                        {!authUserExists && <>
                            <IconButton
                                type="user"
                                tooltip={dict("home.nav.login")}
                                onClick={() => state.showLogin.setValue("login")}/>
                        </>}
                        <Divider style="metabar"/>
                        <IconButton
                            type="date"
                            scale={1.2}
                            stateIconType={hasPublicLiveEvents ? "live" : null}
                            stateIconScale={hasPublicLiveEvents ? 0.95 : null}
                            playFlashAnimation={hasPublicLiveEvents}
                            tooltip={dict("events.title")}
                            onClick={() => state.showEventsFinder.setValue(true)}/>
                        <IconButton
                            type="people"
                            scale={0.9}
                            tooltip={dict("groups.title")}
                            onClick={() => state.showGroupsFinder.setValue(true)}/>
                        {authUserExists &&
                            <IconButton
                                type="academy"
                                tooltip={dict("academy.title")}
                                onClick={() => state.showAcademy.setValue(true)}/>}
                        <MetaBarMenu/>
                        {authUserIsAdmin && <>
                            <Divider style="metabar"/>
                            <IconButton
                                type="settings"
                                scale={0.9}
                                tooltip={dict("admin.title")}
                                selected={showAdmin}
                                onClick={() => router.showRoute(FrontendRoute.ADMIN)}/>
                        </>}
                        {authUserExists && <>
                            <Divider style="metabar"/>
                            <IconButton
                                type="logout"
                                tooltip={dict("logout.title")}
                                onClick={() => state.showLogout.setValue(true)}/>
                        </>}
                    </>
                    : <HomeMenu/>
                }
            </div>
        </aside>
    );

}

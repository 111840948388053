import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {AchievementType} from "../../../../../shared/types/AchievementType";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";
import {AchievementItem} from "../item/AchievementItem";
import {useElementSize} from "../../../hooks/useElementSize";
import {IAchievementData} from "../../../../../shared/models/IAchievementData";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";

/******************************************************************
 * AchievementGroup
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementGroup(props: {
    title: string
    data: IAchievementData[]
    types: AchievementType[]
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const itemsRef = useRef<HTMLDivElement>(null);

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [itemsFrameWidth] = useElementSize(itemsRef);

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isInitialized, setIsInitialized] = useState(false);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setIsInitialized(!!itemsFrameWidth)
    }, [itemsFrameWidth])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function columnTemplate() {
        return `repeat(auto-fill, minmax(${itemsFrameWidth < 500 ? 100 : 160}px, 1fr))`
    }

    function value(type: AchievementType): number {
        return props.data?.find(data => data.type === type)?.value
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer
            className={"achievement-group"}
            isPropertyAvailable={isInitialized}>
            <ContentLayout>
                <Headline
                    text={props.title}
                    style="h2"
                    align="center"/>
                <div
                    style={{
                        maxWidth: 1100,
                        justifySelf: "center",
                        width: "100%"
                    }}>
                    <ContentLayout
                        ref={itemsRef}
                        columnTemplate={columnTemplate()}
                        gap="small"
                        justifyItems="center">
                        {props.types.map(itemType => (
                            <AchievementItem
                                key={itemType}
                                size={itemsFrameWidth < 500 ? "medium" : "normal"}
                                clickable={true}
                                value={value(itemType)}
                                type={itemType}/>
                        ))}
                    </ContentLayout>
                </div>
            </ContentLayout>
        </ComponentInitializer>
    )
}

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import JSConfetti from "js-confetti";
import {AchievementLevelType} from "../../../../shared/types/AchievementLevelType";
import {useServices} from "../../hooks/useServices";

/******************************************************************
 * AchievementConfetti
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementConfetti(props: {
    width: number | string,
    height: number | string,
    style: AchievementLevelType
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const jsConfettiRef = useRef<JSConfetti>()
    const canvasRef = useRef<HTMLCanvasElement>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {sfx} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [state, setState] = useState<"play" | "init">("init")

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!canvasRef.current || jsConfettiRef.current) return
        jsConfettiRef.current = new JSConfetti({
            canvas: canvasRef.current,
        })
        setState("play")
        sfx.playAchievementSound()
    }, [canvasRef.current])

    useEffect(() => {
        if (!jsConfettiRef.current) return
        if (state != "play") return
        jsConfettiRef.current.addConfetti({
            confettiRadius: 6,
            confettiNumber: 200,
            confettiColors: ["#e1b18b"],
        })
        if (props.style == "bronze") return
        setTimeout(() => {
            jsConfettiRef.current.addConfetti({
                confettiRadius: 6,
                confettiNumber: 400,
                confettiColors: ["#c4c4c4"],
            })
        }, 800)
        if (props.style == "silver") return
        setTimeout(() => {
            jsConfettiRef.current.addConfetti({
                confettiRadius: 6,
                confettiNumber: 600,
                confettiColors: ["#e09c07"],
            })
        }, 1600)
        setTimeout(() => {
            jsConfettiRef.current.addConfetti({
                confettiRadius: 6,
                confettiNumber: 600,
                confettiColors: ["#e09c07"],
            })
        }, 2000)
    }, [jsConfettiRef.current || state])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <canvas
            ref={canvasRef}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                userSelect: "none",
                pointerEvents: "none",
                width: props.width,
                height: props.height,
            }}/>
    </>

}

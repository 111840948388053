import {useEffect, useState} from 'react';
import {FrontendRoute} from "../../../shared/routes/FrontendRoute";
import {useServices} from "./useServices";
import {useRouteOwner} from "./useRouteOwner";
import {FrontendBaseRouteType} from "../../../shared/types/FrontendBaseRouteType";
import {FrontendRouteUtils} from "../../../shared/utils/FrontendRouteUtils";

/******************************************************************
 * ROUTES HOOK
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useRouteStates() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state} = useServices()
    const {routeOwnerPath} = useRouteOwner()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [routePath, setRoutePath] = useState<string>(state.route.getValue()?.route)
    const [routeType, setRouteType] = useState<FrontendBaseRouteType>(FrontendRouteUtils.getRouteType(state.route.getValue()?.route))

    const [showSideBar, setShowSideBar] = useState<boolean>(false)

    const [showHome, setShowHome] = useState<boolean>(false)
    const [showGroups, setShowGroups] = useState<boolean>(false)
    const [showEvents, setShowEvents] = useState<boolean>(false)
    const [showPlans, setShowPlans] = useState<boolean>(false)

    const [showUser, setShowUser] = useState<boolean>(false)
    const [showUserHome, setShowUserHome] = useState<boolean>(false)
    const [showUserAchievements, setShowUserAchievements] = useState<boolean>(false)
    const [showUserDrivingSchool, setShowUserDrivingSchool] = useState<boolean>(false)
    const [showUserTraining, setShowUserTraining] = useState<boolean>(false)
    const [showUserEvents, setShowUserEvents] = useState<boolean>(false)
    const [showUserGroups, setShowUserGroups] = useState<boolean>(false)
    const [showUserTracks, setShowUserTracks] = useState<boolean>(false)
    const [showUserGarage, setShowUserGarage] = useState<boolean>(false)

    const [showGroup, setGroup] = useState<boolean>(false)
    const [showGroupHome, setGroupHome] = useState<boolean>(false)
    const [showGroupEvents, setGroupEvents] = useState<boolean>(false)
    const [showGroupTracks, setGroupTracks] = useState<boolean>(false)
    const [showGroupMembers, setGroupMembers] = useState<boolean>(false)

    const [showEvent, setShowEvent] = useState<boolean>(false)
    const [showEventHome, setShowEventHome] = useState<boolean>(false)
    const [showEventResult, setShowEventResult] = useState<boolean>(false)
    const [showEventSession, setShowEventSession] = useState<boolean>(false)
    const [showEventTrack, setShowEventTrack] = useState<boolean>(false)
    const [showEventRequests, setShowEventRequests] = useState<boolean>(false)

    const [showTrack, setShowTrack] = useState<boolean>(false)
    const [showTrackHome, setShowTrackHome] = useState<boolean>(false)
    const [showTrackFilter, setShowTrackFilter] = useState<boolean>(false)

    const [showAdmin, setShowAdmin] = useState<boolean>(false)
    const [showAdminDictionary, setShowAdminDictionary] = useState<boolean>(false)
    const [showAdminArticles, setShowAdminArticles] = useState<boolean>(false)
    const [showAdminUsers, setShowAdminUsers] = useState<boolean>(false)
    const [showAdminSimulator, setShowAdminSimulator] = useState<boolean>(false)
    const [showAdminMailFilter, setShowAdminMailFilter] = useState<boolean>(false)
    const [showAdminDatabase, setShowAdminDatabase] = useState<boolean>(false)
    const [showAdminLogs, setShowAdminLogs] = useState<boolean>(false)
    const [showAdminCaches, setShowAdminCaches] = useState<boolean>(false)
    const [showAdminEnvVars, setShowAdminEnvVars] = useState<boolean>(false)
    const [showAdminMailings, setShowAdminMailings] = useState<boolean>(false)
    const [showAdminMailTemplates, setShowAdminMailTemplates] = useState<boolean>(isAdminMailTmplatesRoute())

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateRouteStates();
        state.route.onChangeSignal.remove(updateRouteStates);
        state.route.onChangeSignal.add(updateRouteStates);
        return () => state.route.onChangeSignal.remove(updateRouteStates);
    }, [routeOwnerPath])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateRouteStates() {

        const routeState = state.route.getValue();
        const routeType = FrontendRouteUtils.getRouteType(routeState?.route)

        // ROUTE
        setRoutePath(routeState?.route);
        setRouteType(routeType)

        // SIDEBAR
        setShowSideBar(routeState?.type != "home"
            && routeState?.type != "groups"
            && routeState?.type != "events"
            && routeState?.type != "plans")

        // HOME-PAGES
        setShowHome(routeState?.type == "home");
        setShowGroups(routeState?.route == FrontendRoute.GROUPS);
        setShowEvents(routeState?.route == FrontendRoute.EVENTS);
        setShowPlans(routeState?.route == FrontendRoute.PLANS);

        // USER-PAGES
        const userRoute = FrontendRouteUtils.parseUserRoute(routeState?.route)
        setShowUser(routeState?.type == "member");
        setShowUserHome(!userRoute?.hasSubPath)
        setShowUserAchievements(routeState?.route == FrontendRoute.USER_ACHIEVEMENTS(routeOwnerPath))
        setShowUserDrivingSchool(routeState?.route == FrontendRoute.USER_DRIVINGSCHOOL(routeOwnerPath))
        setShowUserTraining(routeState?.route == FrontendRoute.USER_TRAINING(routeOwnerPath))
        setShowUserEvents(routeState?.route == FrontendRoute.USER_EVENTS(routeOwnerPath))
        setShowUserGroups(routeState?.route == FrontendRoute.USER_GROUPS(routeOwnerPath))
        setShowUserTracks(routeState?.route == FrontendRoute.USER_TRACKS(routeOwnerPath))
        setShowUserGarage(routeState?.route == FrontendRoute.USER_GARAGE(routeOwnerPath))

        // GROUP-PAGES
        const groupRoute = FrontendRouteUtils.parseGroupRoute(routeState?.route)
        const groupPath = groupRoute?.groupPath
        setGroup(routeState?.type == "group");
        setGroupHome(!groupRoute?.hasSubPath)
        setGroupEvents(routeState?.route == FrontendRoute.GROUP_EVENTS(groupPath))
        setGroupTracks(routeState?.route == FrontendRoute.GROUP_TRACKS(groupPath))
        setGroupMembers(routeState?.route == FrontendRoute.GROUP_MEMBERS(groupPath))

        // EVENT-PAGES
        const eventRoute = FrontendRouteUtils.parseEventRoute(routeState?.route)
        const isEvent = routeState?.type == "event"
        const isEventResult = routeState?.route == FrontendRoute.EVENT_RESULT(eventRoute?.ownerType, eventRoute?.ownerPath, eventRoute?.eventPath, eventRoute?.resultPath);
        const isEventSession = routeState?.route == FrontendRoute.EVENT_SESSION(eventRoute?.ownerType, eventRoute?.ownerPath, eventRoute?.eventPath, eventRoute?.sessionPath);
        const isEventTrack = routeState?.route == FrontendRoute.EVENT_TRACK(eventRoute?.ownerType, eventRoute?.ownerPath, eventRoute?.eventPath, eventRoute?.trackPath);
        const isEventRegistrations = routeState?.route == FrontendRoute.EVENT_PARTICIPANTS(eventRoute?.ownerType, eventRoute?.ownerPath, eventRoute?.eventPath);
        setShowEvent(isEvent)
        setShowEventHome(isEvent && !isEventResult && !isEventSession && !isEventTrack && !isEventRegistrations)
        setShowEventResult(isEventResult)
        setShowEventSession(isEventSession);
        setShowEventTrack(isEventTrack);
        setShowEventRequests(isEventRegistrations);

        // TRACK-PAGE
        const trackRoute = FrontendRouteUtils.parseTrackRoute(routeState?.route)
        setShowTrack(routeState?.type == "track")
        setShowTrackHome(!trackRoute?.filterPath)
        setShowTrackFilter(routeState?.route == FrontendRoute.TRACK_FILTER(trackRoute?.ownerType, trackRoute?.ownerPath, trackRoute?.trackPath, trackRoute?.filterPath))

        // ADMIN-PAGES
        setShowAdmin(routeState?.type == "admin");
        setShowAdminDictionary(routeState?.route == FrontendRoute.ADMIN_DICTIONARY);
        setShowAdminArticles(routeState?.route == FrontendRoute.ADMIN_ARTICLES);
        setShowAdminUsers(routeState?.route == FrontendRoute.ADMIN_USERS);
        setShowAdminSimulator(routeState?.route == FrontendRoute.ADMIN_SIMULATOR);
        setShowAdminMailFilter(routeState?.route == FrontendRoute.ADMIN_MAILFILTER);
        setShowAdminDatabase(routeState?.route == FrontendRoute.ADMIN_DATABASE);
        setShowAdminLogs(routeState?.route == FrontendRoute.ADMIN_LOGS);
        setShowAdminCaches(routeState?.route == FrontendRoute.ADMIN_CACHES);
        setShowAdminEnvVars(routeState?.route == FrontendRoute.ADMIN_ENVVARS);
        setShowAdminMailings(routeState?.route == FrontendRoute.ADMIN_MAILINGS);
        setShowAdminMailTemplates(isAdminMailTmplatesRoute());

    }

    function isAdminMailTmplatesRoute(): boolean {
        return state.route.getValue()?.route == FrontendRoute.ADMIN_MAILTEMPLATES
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        routePath,
        showSideBar,
        showHome,
        showGroups,
        showEvents,
        showPlans,
        showUser,
        showUserHome,
        showUserAchievements,
        showUserTraining,
        showUserDrivingSchool,
        showUserEvents,
        showUserGroups,
        showUserTracks,
        showUserGarage,
        showGroup,
        showGroupHome,
        showGroupEvents,
        showGroupTracks,
        showGroupMembers,
        showEvent,
        showEventHome,
        showEventResult,
        showEventSession,
        showEventTrack,
        showEventRequests,
        showTrack,
        showTrackHome,
        showTrackFilter,
        showAdmin,
        showAdminDictionary,
        showAdminArticles,
        showAdminUsers,
        showAdminSimulator,
        showAdminMailFilter,
        showAdminDatabase,
        showAdminLogs,
        showAdminCaches,
        showAdminEnvVars,
        showAdminMailings,
        showAdminMailTemplates,
        routeType
    };
}

import * as React from "react";

/******************************************************************
 * AchievementItemStar
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementItemStar(props: {
    progress: number
    x: number
    y: number
}) {

    /* ----------------------------------------------------------------
 	 * METHODS
 	 * --------------------------------------------------------------*/

    function maskID() {
        return `reveal-mask-${visibleProgress()}`
    }

    function visibleProgress() {
        let progress = props.progress ?? 0
        if (inProgress()) {
            progress = progress * 0.8 + 0.1
        }
        return progress
    }

    function opacity() {
        if (!props.progress) {
            return 0.08
        }
        if (inProgress()) {
            return 0.25
        }
        return 1
    }

    function inProgress() {
        return props.progress > 0 && props.progress < 1
    }

    function color() {
        if (!props.progress || props.progress == 0) {
            return "white"
        }
        return "#DABE90"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <g transform={`translate(${props.x} ${props.y})`}>
            <g mask={`url(#${maskID()})`}
               opacity={opacity()}
               filter="url(#achievement-star-filter)">
                <path
                    d="M15.6478 2.45204L18.6788 11.7803H28.4871L20.552 17.5455L23.5829 26.8738L15.6478 21.1086L7.71271 26.8738L10.7436 17.5455L2.80855 11.7803H12.6169L15.6478 2.45204Z"
                    fill={color()}/>
            </g>
            <path
                d="M15.6478 2.45204L18.6788 11.7803H28.4871L20.552 17.5455L23.5829 26.8738L15.6478 21.1086L7.71271 26.8738L10.7436 17.5455L2.80855 11.7803H12.6169L15.6478 2.45204Z"
                fill="#DABE90"
                fillOpacity={inProgress() ? 0.02 : 0}
                stroke="#DABE90"
                strokeWidth="1"
                strokeOpacity={inProgress() ? 0.5 : 0}/>
            <defs>
                <mask id={maskID()} x="0" y="0">
                    <rect
                        x="0"
                        y={inProgress() ? (1 - visibleProgress()) * 30 : 0}
                        width="30"
                        height="30"
                        fill="white"/>
                </mask>
                <filter
                    id="achievement-star-filter"
                    x="0.808548"
                    y="0.452042"
                    width="29.6785"
                    height="28.4217"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.57 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2532_2106"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2532_2106" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dx="0.5" dy="0.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0"/>
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2532_2106"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dx="-0.5" dy="-0.5"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.34 0"/>
                    <feBlend mode="normal" in2="effect2_innerShadow_2532_2106" result="effect3_innerShadow_2532_2106"/>
                </filter>
            </defs>
        </g>
    </>

}

import * as React from "react";
import {useEffect, useState} from "react";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {useServices} from "../../../hooks/useServices";
import {ContextMenu} from "../../context/ContextMenu";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {InfoText} from "../../text/infotext/InfoText";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessMaxType} from "../../../../../shared/types/PlanAccessType";
import {LabelButton} from "../../buttons/label/LabelButton";
import {FreePlanMonthlyDrivenTimeState} from "./state/FreePlanMonthlyDrivenTimeState";

/******************************************************************
 * FreePlanMonthlyDrivenTime
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function FreePlanMonthlyDrivenTime() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {authUserFreePlanMonthlyDrivenSeconds, authUserIsFreePlanDriving} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [minutesLeft, setMinutesLeft] = useState<number>(computeMinutesLeft())
    const [showDetails, setShowDetails] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setMinutesLeft(computeMinutesLeft)
    }, [authUserFreePlanMonthlyDrivenSeconds])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function computeMinutesLeft(): number {
        const secondsLeft = defaultSecondsAvailable() - authUserFreePlanMonthlyDrivenSeconds
        if (secondsLeft < 0) {
            return 0
        }
        return secondsLeft / 60
    }

    function defaultSecondsAvailable(): number {
        const eventAccess = PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("events", "free")
        return eventAccess.value * 60
    }

    function secondaryInfo(): string {
        const availableMinutes = defaultSecondsAvailable() / 60
        return dict("freePlanMonthlyDrivenTime.info")
            .replaceAll("{AVAILABLE_TIME}", availableMinutes.toString())
    }

    function primaryInfo(): string {
        return dict("freePlanMonthlyDrivenTime.label")
            .replaceAll("{MINUTES_LEFT}", Math.floor(minutesLeft).toString())
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <div className="free-plan-monthly-driven-time">
            <ContextMenu
                type="custom"
                showMenu={showDetails}
                onToggle={showMenu => showMenu == false ? setShowDetails(false) : null}
                customElement={<FreePlanMonthlyDrivenTimeState
                    minutesLeft={minutesLeft}
                    isDriving={authUserIsFreePlanDriving}
                    onClick={() => setShowDetails(!showDetails)}
                />}>
                <ContentLayout
                    className="free-plan-monthly-driven-time-content"
                    useFrameMargin={true}
                    gap="tiny">
                    <ContentLayout
                        gap="tiny"
                        framed={true}
                        frameColor="yellow">
                        <InfoText
                            text={primaryInfo()}
                            size="tiny"
                            width="tiny"/>
                    </ContentLayout>
                    <ContentLayout
                        gap="tiny"
                        framed={true}
                        frameColor="default">
                        <InfoText
                            text={secondaryInfo()}
                            width="tiny"
                            size="tiny"/>
                    </ContentLayout>
                    <ContentLayout
                        gap="tiny"
                        framed={true}
                        frameColor="default">
                        <InfoText
                            text={dict("freePlanMonthyDriventime.upgrade.info")}
                            width="tiny"
                            size="tiny"/>
                        <LabelButton
                            label={dict("plan.license.upgrade.label")}
                            onClick={() => state.showSubscriptionPlans.setValue(true)}
                            style="primary-small"/>
                    </ContentLayout>
                </ContentLayout>
            </ContextMenu>
        </div>
    </>

}

import {DriverLicenseType} from "../types/DriverLicenseType";
import {IUserSubscription} from "../models/submodels/IUserSubscription";
import {UserRoleType} from "../types/UserRoleType";
import {UserUtils} from "./UserUtils";
import {IUserData} from "../models/IUserData";
import {PlanFeatureDefinitions} from "../plan/PlanFeatureDefinitions";
import {PlanAccessMaxType} from "../types/PlanAccessType";
import {IEventData} from "../models/IEventData";
import {ISessionData} from "../models/ISessionData";

/******************************************************************
 * DriverLicenseUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class DriverLicenseUtils {

    static validLicenseType(subscription: IUserSubscription): DriverLicenseType {
        if (!subscription?.driverLicense) {
            return "free"
        }
        const orderedLicenseType = subscription?.driverLicense
        const hasUnpaidSubscription = !subscription?.isPaid
        if (!orderedLicenseType) {
            return "free"
        }
        return hasUnpaidSubscription ? "free" : orderedLicenseType;
    }

    static hasDriverDrivingTimeLeft(user: IUserData): boolean {
        const validLicenseType = DriverLicenseUtils.validLicenseType(user?.subscription)
        if (validLicenseType == "basic" || validLicenseType == "premium") {
            return true
        }
        const eventAccessDefinition = PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("events", "free")
        const availableSecondsPerMonth = (eventAccessDefinition?.value || 0) * 60
        return (user.freePlanMonthlyDrivenSeconds ?? 0) < availableSecondsPerMonth
    }

    static useFreePlanMonthlyDrivenTime(event: IEventData, session: ISessionData, user: IUserData): boolean {
        if (!user) return false
        if (event?.ownerType == "user") return false
        if (session?.setup?.skipSubscriptionCheck) return false
        const driverLicense = DriverLicenseUtils.validLicenseType(user.subscription)
        if (driverLicense == "basic" || driverLicense == "premium") return false
        return true
    }

    static hasUnpaidSubscription(subscription: IUserSubscription): boolean {
        if (!subscription) {
            return false
        }
        return !subscription?.isPaid
            && (subscription?.driverLicense == "basic" || subscription?.driverLicense == "premium")
    }

    static isUpgrade(requestedDriverLicense: DriverLicenseType, currentDriverLicense: DriverLicenseType): boolean {
        currentDriverLicense = currentDriverLicense || "free"
        requestedDriverLicense = requestedDriverLicense || "free"
        switch (requestedDriverLicense) {
            case "free":
                return false
            case "basic":
                return currentDriverLicense === "free"
            case "premium":
                return currentDriverLicense === "free" || currentDriverLicense === "basic"
        }
    }

    static isDowngrade(requestedDriverLicense: DriverLicenseType, currentDriverLicense: DriverLicenseType): boolean {
        currentDriverLicense = currentDriverLicense || "free"
        requestedDriverLicense = requestedDriverLicense || "free"
        switch (requestedDriverLicense) {
            case "free":
                return currentDriverLicense !== "free"
            case "basic":
                return currentDriverLicense === "premium"
            case "premium":
                return false
        }
    }

    static needHigherLicenseType(
        currentLicense: DriverLicenseType,
        requiredLicense: DriverLicenseType,
        userRole: UserRoleType
    ): boolean {
        if (!currentLicense) {
            return false
        }
        if (UserUtils.doesRoleSkipSubscription(userRole)) {
            return false
        }
        switch (requiredLicense) {
            case "free":
                return false
            case "basic":
                if (currentLicense == "free") return true
                return false
            case "premium":
                if (currentLicense == "free" || currentLicense == "basic") {
                    return true
                }
                return false
        }
    }

    static getLicenseIconName(
        licenseType: DriverLicenseType,
        tiny: boolean = false,
        userRole?: UserRoleType
    ): string {
        if (userRole) {
            switch (userRole) {
                case "judge":
                    if (tiny) return "judgeUserTiny"
                    return "judgeUser"
            }
        }
        if (!licenseType) {
            if (tiny) return "testDriverTiny"
            return "testDriver"
        }
        switch (licenseType) {
            case "free":
                if (tiny) return "testDriverTiny"
                return "testDriver"
            case "basic":
                if (tiny) return "clubDriverTiny"
                return "clubDriver"
            case "premium":
                if (tiny) return "proDriverTiny"
                return "proDriver"
        }
    }
}

import * as React from "react";
import {useEffect, useState} from "react";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../hooks/useGroupState";
import {DRIVETRAIN_TYPES, DrivetrainType} from "../../../../../../shared/types/DrivetrainType";
import {SelectInput, SelectInputOptionType} from "../select/SelectInput";
import {DriftEngineType} from "../../../../../../shared/types/DriftEngineType";
import {DriftTuningType} from "../../../../../../shared/types/DriftTuningType";
import {DriftRacerQuery} from "../../../../../../shared/cars/DriftRacerQuery";

/******************************************************************
 * DriftDriveTypeInput
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriftDrivetrainInput(props: {
    drivetrain: DrivetrainType
    availableEngines: DriftEngineType[]
    availableTunings: DriftTuningType[]
    onChange?: (drivetrain: DrivetrainType) => void
    readonly?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [options, setOptions] = useState<SelectInputOptionType<DrivetrainType>[]>(computeOptions())

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setOptions(computeOptions())
    }, [props.availableEngines, props.availableTunings, props.drivetrain])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function computeOptions(): SelectInputOptionType<DrivetrainType>[] {
        return DRIVETRAIN_TYPES
            .map(type => {
                return {value: type, text: dict(`drivetrain.${type}`)}
            })
            .filter(option => {
                const drivetrains = DriftRacerQuery.getAvailableDrivetrains(props.availableEngines, props.availableTunings)
                const showAny = option.value == "any" && props.drivetrain == "any"
                const showSpecific = drivetrains.includes(option.value)
                return showAny || showSpecific
            })
    }

    function readonly(): boolean {
        return (!isRouteOwnerAuthUser && !isGroupAdmin) || props.readonly
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <SelectInput
            label={dict("drivetrain.type.label")}
            defaultValue={props.drivetrain}
            readonly={readonly()}
            options={options}
            inlineHelp={options.length < DRIVETRAIN_TYPES.length
                ? dict("drivetrain.type.missing.options.help")
                : null}
            onChange={props.onChange}/>
    );

}

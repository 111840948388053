import * as React from "react";
import {ReactElement} from "react";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {useServices} from "../../../../hooks/useServices";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {Icon} from "../../../icons/Icon";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";
import {LeaderboardUtils} from "../../../../../../shared/utils/LeaderboardUtils";

/******************************************************************
 * TableLeaderboardDrivenTimeCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardDrivenTimeCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function primaryValue() {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        const leader = entry.entries[0]
        switch (session.setup.mode) {
            case "race":
                switch (session.setup.timing) {
                    case "sync":
                        switch (entry.type) {
                            case "primary":
                                if (entry.isStartOrder && entry.isReversedStartOrder) {
                                    return TimeUtils.formatDrivenTime(entry.overall.drivenTimeSinceOverallFirstStartSignal)
                                }
                                if (isLeader()) {
                                    return TimeUtils.formatDrivenTime(entry.overall.drivenTimeSinceOverallFirstStartSignal)
                                }
                                if (overallLapGapToLeader() < SharedConfig.STINT_DRIVEN_TIME_AT_LAP_HISTORY_LENGTH) {
                                    const timeGap = LeaderboardUtils.timeGapToHeadingEntry(entry, leader)
                                    if (timeGap > 0) {
                                        return "+" + TimeUtils.formatDrivenTime(timeGap)
                                    }
                                    return TimeUtils.formatDrivenTime(entry.overall.drivenTimeSinceOverallFirstStartSignal)
                                }
                                return "+" + overallLapGapToLeader() + " " + dict("table.drivenTime.laps")
                            case "secondary-team":
                                return TimeUtils.formatDrivenTime(entry.overall.drivenTime)
                        }
                        break;
                    case "async":
                        if (entry.state === "finished") {
                            switch (session.setup.classificationMode) {
                                case "drivers":
                                    const lapGap = bestDrivenTimeStintLapGapToLeader()
                                    if (lapGap > 0) {
                                        return "+" + lapGap + " " + (lapGap == 1
                                            ? dict("table.drivenTime.lap")
                                            : dict("table.drivenTime.laps"))
                                    }
                                    return TimeUtils.formatDrivenTime(finishedAsyncRaceBestDrivenTime())
                                case "teams":
                                case "solo":
                                    return TimeUtils.formatDrivenTime(finishedAsyncRaceBestDrivenTime())
                            }
                        }
                        return TimeUtils.formatDrivenTime(entry.latestStint?.drivenTime)
                }
                break;
            case "gymkhana":
                if (entry.state === "finished") {
                    if (entry.bestGymkhanaStint?.drivenTime === 0) {
                        return "–"
                    }
                    return TimeUtils.formatDrivenTime(entry.bestGymkhanaStint?.drivenTime)
                }
                if (entry.latestStint?.drivenTime === 0) {
                    return "–"
                }
                return TimeUtils.formatDrivenTime(entry.latestStint?.drivenTime)
        }
        return undefined
    }

    function secondaryValue() {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        switch (session.setup.mode) {
            case "race":
                switch (session.setup.timing) {
                    case "sync":
                        if (showPenaltly()) {
                            return dict("driver.falseStart") + " +" + session.setup.falseStartPenalty?.toString() + "s"
                        }
                        if (isLeader()) {
                            return undefined
                        }
                        const lapGap = overallLapGapToLeader()
                        if (lapGap < SharedConfig.STINT_DRIVEN_TIME_AT_LAP_HISTORY_LENGTH
                            && lapGap > 1) {
                            return "+" + lapGap + " " + dict("table.drivenTime.laps")
                        }
                        break
                    case "async":
                        switch (props.entry.state) {
                            case "ready":
                            case "invalid":
                            case "driving":
                                if (showPenaltly()) {
                                    return dict("driver.falseStart") + " +" + session.setup.falseStartPenalty?.toString() + "s"
                                }
                                if ((entry.bestDrivenTimeStint.drivenTime ?? 0) === 0
                                    || (entry.overall.drivenStints ?? 0) <= 1) {
                                    return undefined
                                }
                                return TimeUtils.formatDrivenTime(finishedAsyncRaceBestDrivenTime())
                            case "finished":
                                if (showPenaltly()) {
                                    return dict("driver.falseStart") + " +" + session.setup.falseStartPenalty?.toString() + "s"
                                }
                                if (isLeader()) {
                                    return undefined
                                }
                                if (bestDrivenTimeStintLapGapToLeader() > 0) {
                                    return TimeUtils.formatDrivenTime(finishedAsyncRaceBestDrivenTime())
                                }
                                const gap = timeGapToLeader()
                                if (!gap) {
                                    return undefined
                                }
                                return "+" + TimeUtils.formatDrivenTime(gap);
                        }
                }
        }
        return undefined
    }

    function isLeader() {
        const session: ISessionData = props.entry?.session
        const setup = session?.setup
        if (setup?.showOnlyOwnStints) {
            return true
        }
        return props.entry.position === 1
    }

    function finishedAsyncRaceBestDrivenTime(otherEntry?: ISessionLeaderboardEntry): number {
        const session: ISessionData = props.entry.session
        const entry = otherEntry ?? props.entry
        switch (session.setup.classificationMode) {
            case "solo":
            case "drivers":
                return entry.bestDrivenTimeStint?.drivenTime ?? 0
            case "teams":
                switch (session.setup.mode) {
                    case "race":
                        switch (entry.type) {
                            case "primary":
                                switch (session.setup.teamScoringMode) {
                                    case "best":
                                        return entry.bestDrivenTimeStint?.drivenTime ?? 0
                                    case "average":
                                        return entry.overall?.avgTeamDrivenTime ?? 0
                                    case "sum":
                                        return entry.overall?.sumTeamDrivenTime
                                }
                                break
                            case "secondary-team":
                                return entry.bestDrivenTimeStint?.drivenTime ?? 0
                        }
                        break
                }
        }
        return undefined
    }

    function showPenaltly(): boolean {
        const session: ISessionData = props.entry.session
        if (session.setup.falseStartPenalty == 0) {
            return false
        }
        switch (session.setup.mode) {
            case "lap":
            case "gymkhana":
                return false
            case "race":
                switch (session.setup.timing) {
                    case "sync":
                        return props.entry.overall.falseStart
                    case "async":
                        switch (props.entry.state) {
                            case "ready":
                            case "invalid":
                            case "driving":
                                return props.entry.latestStint.falseStart
                            case "finished":
                                return props.entry.bestDrivenTimeStint.falseStart
                        }
                }
        }
    }

    function overallLapGapToLeader() {
        return LeaderboardUtils.lapGapToHeadingEntry(props.entry, props.entry.entries[0])
    }

    function bestDrivenTimeStintLapGapToLeader() {
        const driverDrivenLaps = props.entry.bestDrivenTimeStint.drivenLaps
        const leaderDrivenLaps = props.entry.entries[0].bestDrivenTimeStint.drivenLaps
        const gap = (leaderDrivenLaps ?? 0) - (driverDrivenLaps ?? 0)
        return gap < 0 ? 0 : gap
    }

    function timeGapToLeader() {
        if (isLeader()) {
            return undefined
        }
        const thisDrivenTime = drivenTime()
        const leaderDrivenTime = drivenTime(props.entry.entries[0])
        const gap = (thisDrivenTime ?? 0) - (leaderDrivenTime ?? 0)
        if (gap < 0) {
            return undefined
        }
        return gap
    }

    function drivenTime(entry?: ISessionLeaderboardEntry) {
        if (!entry) entry = props.entry
        switch (props.entry.session.setup.timing) {
            case "sync":
                return entry.overall?.drivenTimeSinceOverallFirstStartSignal ?? 0
            case "async":
                switch (props.entry.session.setup.mode) {
                    case "race":
                        return finishedAsyncRaceBestDrivenTime(entry)
                    case "gymkhana":
                        return entry.bestGymkhanaStint?.drivenTime ?? 0
                }
        }
        return 0
    }

    function primaryPrefixIcon(): ReactElement {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        if (entry.state !== "finished") {
            return null
        }
        switch (session.setup.timing) {
            case "sync":
                return null
        }
        switch (session.setup.classificationMode) {
            case "teams":
                switch (entry.type) {
                    case "primary":
                        switch (session.setup.teamScoringMode) {
                            case "best":
                                return <Icon type="star" scale={0.5}/>
                            case "average":
                                return <Icon type="avg" scale={0.5}/>
                            case "sum":
                                return <Icon type="sum" scale={0.5}/>
                        }
                        break
                    case "secondary-team":
                        return null
                }
        }
        return null
    }

    function size() {
        switch (props.entry.type) {
            case "primary":
                return "normal"
            case "secondary-team":
                return "small"

        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-driventime-cell"
            data-mode={props.entry.session.setup.mode}
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-size={size()}
            data-theme={theme()}
            data-is-startorder={props.entry.isStartOrder}>
            <div className="table-leaderboard-driventime-cell-primary">
                {primaryPrefixIcon()}{primaryValue()}
            </div>
            {secondaryValue() && <div
                className="table-leaderboard-driventime-cell-secondary"
                data-penalty={showPenaltly()}>
                {secondaryValue()}
            </div>}
        </div>
    );

}

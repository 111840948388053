import * as React from "react";
import {AchievementType} from "../../../../../shared/types/AchievementType";
import {Modal} from "../Modal";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../hooks/useServices";
import {AchievementItem} from "../../../ui/achievements/item/AchievementItem";
import {Headline} from "../../../ui/text/headings/Headline";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {AchievementAwardDescription} from "../../../ui/achievements/award/AchievementAwardDescription";
import {IAchievementDefinition} from "../../../../../shared/achievements/IAchievementDefinition";
import {AchievementQuery} from "../../../../../shared/achievements/AchievementQuery";
import {AchievementLevelType} from "../../../../../shared/types/AchievementLevelType";

/******************************************************************
 * AchievementInfoModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementInfoModal(props: {
    type: AchievementType,
    value?: number,
    onRequestClose: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function task(level: AchievementLevelType): string {
        return dict(`achievements.${props.type}.task`)
            .replaceAll("{VALUE}", definition()[level].value.toLocaleString())
    }

    function definition(): IAchievementDefinition {
        return AchievementQuery.definitionByType(props.type)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            closeAction={props.onRequestClose}>
            <ContentLayout
                justifyItems="center">
                <AchievementItem
                    value={props.value}
                    showValueInfo={true}
                    type={props.type}/>
                <ContentLayout framed={true}>
                    <Headline
                        text={dict(`achievements.${props.type}.label`)}
                        align="center"
                        style="h2"/>
                    <InfoText
                        text={dict(`achievements.${props.type}.description`)}
                        style={"abstract"}
                        align="center"/>
                    <AchievementAwardDescription
                        level={"bronze"}
                        type={props.type}
                        task={task("bronze")}/>
                    <AchievementAwardDescription
                        level={"silver"}
                        type={props.type}
                        task={task("silver")}/>
                    <AchievementAwardDescription
                        level={"gold"}
                        type={props.type}
                        task={task("gold")}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}

import * as React from "react";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {useServices} from "../../../hooks/useServices";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {LabelButton, LabelButtonStyleType} from "../../buttons/label/LabelButton";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {PlanHeadline} from "../../text/headings/PlanHeadline";
import {IUserSubscription} from "../../../../../shared/models/submodels/IUserSubscription";
import {UserRoleType} from "../../../../../shared/types/UserRoleType";
import {UserUtils} from "../../../../../shared/utils/UserUtils";

/******************************************************************
 * DriverLicenseInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriverLicenseInfo(props: {
    subscription: IUserSubscription
    role: UserRoleType
    style: "sidebar" | "main"
    isRouteOwner?: boolean
    onHeadlineClick?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODS
 	 * --------------------------------------------------------------*/

    function validLicenseType(): DriverLicenseType {
        return DriverLicenseUtils.validLicenseType(props.subscription);
    }

    function upgradeButtonStyle(): LabelButtonStyleType {
        switch (validLicenseType()) {
            case "free":
                return "primary-tiny";
            case "basic":
                return "tertiary-tiny"
            case "premium":
                return null
        }
    }

    function showUpgradeButton(): boolean {
        if (UserUtils.doesRoleSkipSubscription(props.role)) {
            return false
        }
        return props.isRouteOwner && !!upgradeButtonStyle()
    }

    function licenceName(): string {
        switch (props.role) {
            case "judge":
                return dict("role.judge.title")
        }
        return dict("plan.type." + validLicenseType() + ".title")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="driver-license-info"
            gap="tiny"
            columnTemplate={props.isRouteOwner && props.style == "sidebar" ? "auto min-content" : null}
            justifyContent={props.style == "sidebar" ? (showUpgradeButton() ? "space-between" : null) : "center"}
            justifyItems={props.style == "sidebar" ? null : "center"}
            alignItems="center">
            <PlanHeadline
                title={licenceName()}
                size={props.style == "sidebar" ? "small" : "medium"}
                onClick={props.onHeadlineClick}/>
            {showUpgradeButton() &&
                <LabelButton
                    label={dict("plan.license.upgrade.label")}
                    style={upgradeButtonStyle()}
                    onClick={() => state.showSubscriptionPlans.setValue(true)}/>}
        </ContentLayout>
    );

}

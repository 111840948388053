import {FrontendServices} from "../FrontendServices";

/******************************************************************
 * FrontendSFX
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FrontendSFX {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _audioContext: AudioContext

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
        this.initAudioContext()
        // window.addEventListener("click", () => this.initAudioContext())
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async playAchievementSound() {
        this.playSound("/resources/sfx/achievement.mp3")
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initAudioContext() {
        if (this._audioContext) {
            if (this._audioContext.state === 'suspended') {
                this._audioContext.resume()
            }
            return
        }
        this._audioContext = new (window.AudioContext || window['webkitAudioContext'])()
        const silentBuffer = this._audioContext.createBuffer(1, 1, this._audioContext.sampleRate);
        const silentSource = this._audioContext.createBufferSource();
        silentSource.buffer = silentBuffer;
        silentSource.connect(this._audioContext.destination);
        silentSource.start(0);
    }

    private async playSound(url: string) {
        if (!this._audioContext) return
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const source = this._audioContext.createBufferSource();
        source.buffer = await this._audioContext.decodeAudioData(arrayBuffer);
        source.connect(this._audioContext.destination);
        source.start(0);
    }

}

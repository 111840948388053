import * as React from "react";
import {createRef, useEffect, useState} from "react";
import {AchievementType} from "../../../../../../shared/types/AchievementType";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * AchievementItemLabel
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementItemLabel(props: {
    type: AchievementType
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const svgTextRef = createRef<SVGTextElement>();

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, language} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [label, setLabel] = useState("Fahrbahn-Kenner");
    const [textTransformValue, setTextTransformValue] = useState("scale(1)");

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setLabel(dict(`achievements.${props.type}.label`))
    }, [props.type])

    useEffect(() => {
        setTimeout(() => {
            setLabel(dict(`achievements.${props.type}.label`))
        }, 100)
    }, [language])

    useEffect(() => {
        updateTextScale()
    }, [label])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateTextScale() {
        if (!svgTextRef.current) return
        const MAX_TEXT_WIDTH = 105;
        const bbox = svgTextRef.current.getBBox();
        const textWidth = bbox.width;
        let scale = MAX_TEXT_WIDTH / textWidth;
        if (scale > 1) scale = 1;
        const ox = 63.5;
        const oy = 92;
        setTextTransformValue(`translate(${ox}, ${oy}) scale(${scale}) translate(${-ox}, ${-oy})`)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <text
            ref={svgTextRef}
            className="svg-achievement-item-text"
            x="50%"
            y="96"
            transform={textTransformValue}
            textAnchor="middle">
            {label}
        </text>
    );

}

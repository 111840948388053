/******************************************************************
 * AchievementLevelType
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export const ACHIEVEMENT_LEVEL_TYPES = [
    "none",
    "bronze",
    "silver",
    "gold"
] as const

export type AchievementLevelType = typeof ACHIEVEMENT_LEVEL_TYPES[number];

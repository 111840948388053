/******************************************************************
 * DrivetrainType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const DRIVETRAIN_TYPES = [
    "any",
    "RWD",
    "AWD",
    "FWD",
] as const

export type DrivetrainType = typeof DRIVETRAIN_TYPES[number];

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {IAchievementData} from "../../../../../shared/models/IAchievementData";
import {useServices} from "../../../hooks/useServices";
import {IAchievementDefinition} from "../../../../../shared/achievements/IAchievementDefinition";
import {AchievementQuery} from "../../../../../shared/achievements/AchievementQuery";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";
import {AchievementItem} from "../item/AchievementItem";
import {Spacer} from "../../utils/spacer/Spacer";
import {gsap} from "gsap";
import {SpeakerUtils} from "../../../../utils/SpeakerUtils";

/******************************************************************
 * AchievementRewardAnimation
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementRewardAnimation(props: {
    data: IAchievementData,
    show: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>()
    const hasPlayed = useRef<boolean>(false)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, speaker, language} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [definition] = useState<IAchievementDefinition>(AchievementQuery.definitionByType(props.data.type))

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!props.show) {
            showHeadlines(false)
            return
        }
        if (hasPlayed.current) return
        speaker.speak(dict("achievement.reward.annoucement")
            .replaceAll("{NICK}", SpeakerUtils.nickToSpeak(props.data.user, language)))
        hasPlayed.current = true
        showHeadlines(true)
    }, [props.show])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showHeadlines(show: boolean) {
        const headlines = rootRef.current.querySelectorAll(".headline")
        gsap.to(headlines, {
            duration: 0.7,
            stagger: 0.1,
            scale: show ? 1 : 0.8,
            opacity: show ? 1 : 0,
            ease: "power4.out"
        })
    }

    function congratsText() {
        return dict("achievement.reward.title")
            .replaceAll("{NICK}", props.data.user?.nick)
    }

    function task() {
        const levelType = AchievementQuery.computeLevelAndProgress(props.data.type, props.data.value).type
        return dict(`achievements.${props.data.type}.task`)
            .replaceAll("{VALUE}", definition[levelType].value.toLocaleString())
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={rootRef}
            className="achievement-reward-animation"
            justifyContent="center">
            <AchievementItem
                type={props.data.type}
                size="huge"
                value={props.data.value}/>
            <ContentLayout gap="small">
                <Headline
                    text={congratsText()}
                    align="center"
                    style="h1-overlay"/>
                <Headline
                    text={dict("achievement.reward.task")}
                    align="center"
                    style={"h4"}/>
                <Headline
                    text={task()}
                    align="center"
                    style={"h1-overlay"}
                    maxWidth={400}/>
                <Spacer height="small"/>
            </ContentLayout>
        </ContentLayout>
    );

}

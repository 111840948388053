import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {PendingIcon} from "../../../icons/PendingIcon";
import {GroupIcon} from "../../../icons/GroupIcon";
import {CharUtils} from "../../../../../../shared/utils/CharUtils";
import {gsap} from "gsap";

/******************************************************************
 * FreePlanMonthlyDrivenTimeState
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function FreePlanMonthlyDrivenTimeState(props: {
    minutesLeft: number
    isDriving: boolean
    onClick: () => void
}) {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)
    const intervallRef = useRef<number>(null)

    /* ----------------------------------------------------------------
 	 * STATE
 	 * --------------------------------------------------------------*/

    const [minutesLeft, setMinutesLeft] = useState<number>(props.minutesLeft)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setMinutesLeft(props.minutesLeft)
        if (props.isDriving) {
            intervallRef.current = window.setInterval(() => {
                setMinutesLeft(prevMinutesLeft => prevMinutesLeft - (1 / 60))
            }, 1000)
        } else {
            clearInterval(intervallRef.current)
        }
    }, [props.minutesLeft, props.isDriving])

    useEffect(() => {
        if (!rootRef.current) return
        const rootElement = rootRef.current
        const pendingIcon = rootElement.querySelector(".free-plan-monthly-driven-time-state-icon > .pending-icon")
        gsap.killTweensOf(pendingIcon)
        gsap.to(pendingIcon, {
            duration: 2,
            rotation: props.isDriving ? 360 : 0,
            ease: "power4.inOut",
            repeat: props.isDriving ? -1 : 0
        })
    }, [props.isDriving, rootRef.current])

    function formatTime (minutes: number): string {
        if (minutes < 0) return "0:00"
        const mins = Math.floor(minutes)
        const secs = Math.floor((minutes - mins) * 60)
        return CharUtils.replaceZeroWithO(`${mins}:${secs.toString().padStart(2, '0')}`)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            ref={rootRef}
            className="free-plan-monthly-driven-time-state"
            data-time-state={minutesLeft <= 0
                ? "time-over"
                : minutesLeft <= 5
                    ? "time-critical"
                    : "time-ok"}
            onClick={props.onClick}>
            <div className="free-plan-monthly-driven-time-state-icon">
                <GroupIcon/>
                <PendingIcon/>
            </div>
            <div className="free-plan-monthly-driven-time-state-value">
                {formatTime(minutesLeft)}<span>min</span>
            </div>
        </div>
    );

}

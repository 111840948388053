import * as React from "react";
import {useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {IEventData} from "../../../../../shared/models/IEventData";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {SelectInput} from "../../../ui/form/elements/select/SelectInput";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";

/******************************************************************
 * EventParticipationRequestModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventParticipationRequestModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict, api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [eventData] = useState<IEventData>(state.showEventParticipationRequest.getValue())
    const [progressing, setProgressing] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(state.showConfirm.getValue()?.confirmed)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function infoText(): string {
        let text = dict("event.participation.request.none.text")
        switch (eventData?.drivers?.access) {
            case "only-registered-drivers-after-approval":
                text = dict("event.participation.request.approval.text")
                break
            case "only-registered-drivers-after-paid":
                text = dict("event.participation.request.fee.text")
                break
        }
        return text.replaceAll("[EVENT_NAME]", eventData?.name)
    }

    async function sendEventParticipationRequest() {
        setProgressing(true)
        const success = await api.event.driverRequest(eventData._id)
        setProgressing(false)
        if (success) {
            state.showEventParticipationRequest.setValue(null)
        }
    }

    function confirmCheckText(confirmed: boolean) {
        return confirmed
            ? dict("event.participation.request.yes")
            : dict("event.participation.request.no")
    }

    function close() {
        state.showEventParticipationRequest.setValue(null)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            className={"event-participation-request-modal"}
            closeAction={close}>
            <Headline
                text={dict("event.participation.request.modal.title")}
                style="modal"/>
            <ContentLayout>
                <InfoText
                    text={infoText()}/>
                <SelectInput
                    onChange={(value) => setIsConfirmed(value == "yes")}
                    defaultValue={isConfirmed ? "yes" : "no"}
                    options={[
                        {value: "no", text: confirmCheckText(false)},
                        {value: "yes", text: confirmCheckText(true)}
                    ]}/>
                <ContentLayout columns={2}>
                    <LabelButton
                        onClick={close}
                        label={dict("generic.abort")}
                        style="secondary"/>
                    <LabelButton
                        onClick={sendEventParticipationRequest}
                        disabled={!isConfirmed}
                        progressing={progressing}
                        label={dict("event.participation.request.send.button.label")}
                        style="primary"/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    )

}

import * as React from "react";
import {IArticleAchievementBlockData} from "../../../../../../shared/models/IArticleData";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {Input} from "../../../form/elements/input/Input";
import {useServices} from "../../../../hooks/useServices";
import {LanguageType} from "../../../../../../shared/types/LanguageType";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {SelectInput, SelectInputOptionType} from "../../../form/elements/select/SelectInput";
import {ACHIEVEMENT_TYPES, AchievementType} from "../../../../../../shared/types/AchievementType";
import {ACHIEVEMENT_LEVEL_TYPES, AchievementLevelType} from "../../../../../../shared/types/AchievementLevelType";
import {AchievementItem} from "../../../achievements/item/AchievementItem";
import {AchievementAwardDescription} from "../../../achievements/award/AchievementAwardDescription";

/******************************************************************
 * ArticleAchievementBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleAchievementBlock(props: {
    data: IArticleAchievementBlockData
    language: LanguageType
    editing: boolean
    onChange: (data: IArticleAchievementBlockData) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {authUserHasPermission} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateTask(task: string, translate?: boolean): Promise<Response> {
        if (translate) {
            task = await api.translate.text(task, null, props.language)
        }
        props.data.task = task
        props.onChange(props.data)
        return null
    }

    async function updateAchievenemtItemType(type: AchievementType): Promise<Response> {
        props.data.type = type
        props.onChange(props.data)
        return null
    }

    async function updateAchievenemtLevelType(level: AchievementLevelType): Promise<Response> {
        props.data.level = level
        props.onChange(props.data)
        return null
    }

    function achievementItemTypeOptions(): SelectInputOptionType[] {
        return ACHIEVEMENT_TYPES.map(achievementType => ({
            value: achievementType,
            text: dict(`achievements.${achievementType}.label`)
        }))
    }

    function achievementLevelTypeOptions(): SelectInputOptionType[] {
        return ACHIEVEMENT_LEVEL_TYPES.map(achievementLevel => ({
            value: achievementLevel,
            text: dict(`achievements.level.${achievementLevel}`)
        }))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="article-achievement-block"
            gap="small"
            useFrameMargin={!props.editing}
            justifyItems={props.editing ? "stretch" : "center"}>
            {props.editing
                ? <ContentLayout columnTemplate="1fr 2fr">
                    <ContentLayout gap="small" justifyItems="center">
                        <AchievementItem
                            level={props.data.level}
                            type={props.data.type}
                            size="small"/>
                        <SelectInput
                            size="small"
                            label={dict("article.achievement.item.type.label")}
                            defaultValue={props.data.type}
                            options={achievementItemTypeOptions()}
                            onChange={updateAchievenemtItemType}/>
                        <SelectInput
                            size="small"
                            label={dict("article.achievement.level.label")}
                            defaultValue={props.data.level}
                            options={achievementLevelTypeOptions()}
                            onChange={updateAchievenemtLevelType}/>
                    </ContentLayout>
                    <ContentLayout>
                        <Input
                            type="textarea"
                            size="small"
                            label={dict("article.achievement.task.label")}
                            defaultValue={props.data.task}
                            required={true}
                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                            onCustomLinkClick={() => updateTask(props.data.task, true)}
                            action={updateTask}/>
                    </ContentLayout>
                </ContentLayout>
                : <AchievementAwardDescription
                    level={props.data.level}
                    type={props.data.type}
                    task={props.data.task}/>
            }
        </ContentLayout>
    )
}

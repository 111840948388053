import * as React from "react";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Spacer} from "../../utils/spacer/Spacer";
import {AchievementItem} from "../item/AchievementItem";
import {InfoText} from "../../text/infotext/InfoText";
import {AchievementLevelType} from "../../../../../shared/types/AchievementLevelType";
import {AchievementType} from "../../../../../shared/types/AchievementType";
import {useRef} from "react";
import {useElementSize} from "../../../hooks/useElementSize";

/******************************************************************
 * AchievementAwardDescription
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementAwardDescription(props: {
    level: AchievementLevelType,
    type: AchievementType,
    task: string,
}) {

    /* ----------------------------------------------------------------
 	 * REFs
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [rootWidth] = useElementSize(rootRef);

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={rootRef}
            columnTemplate="1fr 2fr"
            alignItems="center">
            <ContentLayout justifyItems="end" gap="none">
                <AchievementItem
                    level={props.level}
                    type={props.type}
                    size="small"/>
            </ContentLayout>
            <ContentLayout gap="small">
                <InfoText
                    text={props.task}
                    style="abstract"
                    size={rootWidth < 400 ? "small" : "normal"}/>
                <Spacer height="small"/>
            </ContentLayout>
        </ContentLayout>
    );

}

import * as React from "react";
import ReactMarkdown from "react-markdown";

/******************************************************************
 * LabelValueText
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LabelValueText(props: {
    label: string
    value: string
    stopPropagation?: boolean
    size?: "normal" | "small" | "large"
    align?: "left" | "right" | "center"
    warning?: boolean
    className?: string
    onClick?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (props.stopPropagation) {
            event.stopPropagation()
        }
        props.onClick?.()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className={"label-value-text" + (props.className ? " " + props.className : "")}
            data-clickable={!!props.onClick}
            data-warning={props.warning}
            data-align={props.align ?? "left"}
            data-size={props.size ?? "normal"}
            onClick={onClick}>
            <div className="label-value-text-label">{props.label}</div>
            <ReactMarkdown className="label-value-text-value">{props.value}</ReactMarkdown>
        </div>
    );

}

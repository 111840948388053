import * as React from "react";
import {ApprovalStateType} from "../../../../../shared/types/ApprovalStateType";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * ApprovalStateInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ApprovalStateInfo(props: {
    state: ApprovalStateType
    hasVideo?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODS
 	 * --------------------------------------------------------------*/

    function text(): string {
        switch (props.state) {
            case "approved":
                return dict("approval.state.info.approved")
            case "rejected":
                return dict("approval.state.info.rejected")
            case "pending":
            default:
                if (!props.hasVideo) {
                    return dict("approval.state.info.pending.missingVideo")
                }
                return dict("approval.state.info.pending")
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="approval-state-info"
            data-state-type={props.state ?? "pending"}>
            {text()}
        </div>
    );

}

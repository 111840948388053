import * as React from "react";
import {ReactElement, useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {StintCarInfo} from "./car/StintCarInfo";
import {StintTrackInfo} from "./track/StintTrackInfo";
import {StintLapsInfo} from "./laps/StintLapsInfo";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {ITabbedContentChildProps} from "../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {StintLapsMetrics} from "./metrics/StintLapsMetrics";
import {StintVideoProof} from "./videoproof/StintVideoProof";
import {useGroupState} from "../../../../hooks/useGroupState";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useServices} from "../../../../hooks/useServices";
import {useSessionState} from "../../../../hooks/useSessionState";
import {IStintData} from "../../../../../../shared/models/IStintData";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {StintTargetsInfo} from "./targets/StintTargetsInfo";
import {StintGymkhanaMetrics} from "./metrics/StintGymkhanaMetrics";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {StintMetaMetrics} from "./metrics/meta/StintMetaMetrics";
import {StintDeviceInfo} from "./device/StintDeviceInfo";
import {ApprovalStateInfo} from "../../../../ui/info/approval/ApprovalStateInfo";

/******************************************************************
 * StintDetail
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function StintDetail(props: {
    stintID: MongoObjectIDType
    tabbedContent?: ITabbedContentChildProps
    onStintUpdated: (stint: IStintData) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {authUserID} = useAuthUser()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {api} = useServices();
    const {sessionLeaderboard, sessionMode, sessionUseStintApproval} = useSessionState()
    const [isMobileMode] = useMobileStyle(ref, 815)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
    const [stint, setStint] = useState<IStintData>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (isDataLoading) return
        loadDetailedStintData()
    }, [props.stintID, sessionLeaderboard])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadDetailedStintData() {
        if (!props.stintID) return
        setIsDataLoading(true)
        const stintData = await api.stint.getStint(props.stintID)
        props.onStintUpdated(stintData)
        setStint(stintData);
        setIsDataLoading(false)
    }

    function canEdit(): boolean {
        return isRouteOwnerAuthUser
            || isGroupAdmin
            || stint?.user._id === authUserID
    }

    function hasVideoProof(): boolean {
        return !!stint.youtubeURL;
    }

    function lapsAndTargetsInfo(): ReactElement {
        switch (sessionMode) {
            case "lap":
            case "race":
                return <StintLapsInfo stint={stint}/>
            case "gymkhana":
                return <StintTargetsInfo stint={stint}/>
        }
    }

    function stintMetrics(): ReactElement {
        switch (sessionMode) {
            case "lap":
            case "race":
                return <StintLapsMetrics
                    stint={stint}/>
            case "gymkhana":
                return <StintGymkhanaMetrics
                    stint={stint}/>
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!!stint}>
            <ContentLayout
                ref={ref}
                className="stint-detail">
                {sessionUseStintApproval &&
                    <ApprovalStateInfo
                        state={stint?.approvalState}
                        hasVideo={!!stint?.youtubeURL}/>}
                <ContentLayout
                    columnTemplate={isMobileMode ? "1fr" : "1fr 3fr 1fr"}>
                    {stint && <>
                        {isMobileMode &&
                            <ContentLayout>
                                <StintMetaMetrics stint={stint}/>
                                <StintCarInfo stint={stint}/>
                                <StintDeviceInfo stint={stint}/>
                                <StintTrackInfo stint={stint}/>
                                {stintMetrics()}
                                {lapsAndTargetsInfo()}
                                {stint.state !== "invalid" && (canEdit() || hasVideoProof()) &&
                                    <StintVideoProof stint={stint}/>}
                            </ContentLayout>}
                        {!isMobileMode && <>
                            <ContentLayout className="left">
                                <StintCarInfo stint={stint}/>
                                <StintDeviceInfo stint={stint}/>
                                <StintTrackInfo stint={stint}/>
                            </ContentLayout>
                            <ContentLayout className="main">
                                {lapsAndTargetsInfo()}
                            </ContentLayout>
                            <ContentLayout className="right">
                                <StintMetaMetrics stint={stint}/>
                                {stintMetrics()}
                                {stint.state !== "invalid" && (canEdit() || hasVideoProof()) &&
                                    <StintVideoProof stint={stint}/>}
                            </ContentLayout>
                        </>}
                    </>}
                </ContentLayout>
            </ContentLayout>
        </ComponentInitializer>
    );

}

/******************************************************************
 * DriftTuningType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const DRIFT_TUNINGS = [
    "any",
    "club",
    "4-WHEEL BASIC 533 PS",
    "4-WHEEL BASIC 550 PS",
    "4-WHEEL BASIC 565 PS",
    "4-WHEEL DRIVE 700 PS",
    "4-WHEEL DRIVE 710 PS",
    "4-WHEEL DRIVE 750 PS",
    "4-WHEEL DRIVE 920 PS",
    "4-WHEEL DRIVE 980 PS",
    "4-WHEEL DRIVE 1000 PS",
    "5,2L 480 PS",
    "5,2L 510 PS",
    "5,6L BIG BOOST 1000 PS",
    "5,6L FIA-GT1 600 PS",
    "5,6L FAI-GT1 600 PS",
    "5,6L TWIN TURBO 650 PS",
    "BASIC",
    "BASIC 550 PS",
    "BASIC SETUP 275 PS",
    "BASIC SETUP 280 PS",
    "BASIC SETUP 373 PS",
    "BASIC SETUP 375 PS",
    "BASIC SETUP 440 PS",
    "BASIC SETUP 450 PS",
    "BASIC SETUP 533 PS",
    "BASIC SETUP 550 PS",
    "BASIC SETUP 565 PS",
    "DTE-CHIPTUNING 633 PS",
    "DTE-CHIPTUNING 750 PS",
    "FWD 300 PS",
    "FWD 303 PS",
    "FWD 550 PS",
    "FWD 565 PS",
    "HIGH RPM BOOST 980 PS",
    "NISMO TUNED 600 PS",
    "PERFORMANCE-KIT 620 PS",
    "PERFORMANCE-KIT 700 PS",
    "PERFORMANCE-KIT 710 PS",
    "RACE SETUP 390 PS",
    "SINGLE-TURBO 800 PS",
    "SINGLE-TURBO 850 PS",
    "SINGLE-TURBO 950 PS",
    "SINGLE-TURBO 1000 PS",
    "SPORT CHRONO-PAKET",
    "Turbo S",
    "SUPERCHARGER 780 PS",
    "SUPERCHARGER 920 PS",
    "TUNED 320 PS",
    "TUNED 350 PS",
    "TURBO UPGRADE 700 PS",
    "WORLD RECORD 1190 PS",
    "Stage I - 380 PS",
    "Stage II - 533 PS",
    "Stage III - 670 PS",
    "Stage IV - 930 PS",
    "4,0L 340 PS",
    "6,2L 460 PS",
    "6,2L 550 PS",
    "6,2L 780 PS",
    "WRX STi",
    "WRC",
    "Stage II",
    "Stage III",
    "Stage II RWD",
    "Stage III RWD",
    "STi RWD",
    "WRC RWD",
    "Gr.4 - 320 PS",
    "Gr.B - 360 PS",
    "20V - 500 PS",
    "20V - 700 PS",
    "RWD - 320 PS",
    "RWD - 360 PS",
    "RWD - 500 PS",
    "RWD - 700 PS",
    "4,0L 355 PS",
    "5,2L 420 PS",
    "5,2L 455 PS",
    "4,8L 710 PS"
] as const

export type DriftTuningType = typeof DRIFT_TUNINGS[number];

export function isDriftTuning(value: string): boolean {
    if (value?.length > 64) {
        return false
    }
    if (value?.toLowerCase().includes("club")) {
        return true
    }
    if (value?.toLowerCase().includes("any")) {
        return true
    }
    return DRIFT_TUNINGS.includes(value as DriftTuningType)
}


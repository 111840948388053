import {DRIFT_TUNINGS, DriftTuningType, isDriftTuning} from "../types/DriftTuningType";
import {DriftRacerDB} from "./DriftRacerDB";
import {DriftTuningGroupType} from "../types/DriftTuningGroupType";
import {CarVendorType} from "../types/CarVendorType";
import {DRIFT_ENGINES, DriftEngineType} from "../types/DriftEngineType";
import {DrivetrainType} from "../types/DrivetrainType";

/******************************************************************
 * DriftRacerQuery
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class DriftRacerQuery {

    /* ----------------------------------------------------------------
 	 * PUBLIC METHODES
 	 * --------------------------------------------------------------*/

    static getGroupTunings(category: DriftTuningGroupType): DriftTuningType[] {
        switch (category) {
            case "clubsport":
                return ["club"]
            case "any":
                return ["any"]
            default:
                return Array.from(
                    new Set(
                        DriftRacerDB
                            .filter(car => car.tuningGroup == category)
                            .map(car => car.tuning)));
        }
    }

    static getVendorEngines(category: CarVendorType): DriftEngineType[] {
        switch (category) {
            case "any":
                return ["any"]
            default:
                return Array.from(new Set(
                    DriftRacerDB
                        .filter(car => car.vendor == category)
                        .map(car => car.engine)))
        }
    }

    static getAvailableDrivetrains(engines: DriftEngineType[], tunings: DriftTuningType[]): DrivetrainType[] {
        let enginesDrivetrains = DriftRacerQuery.getEnginesDrivetrains(engines)
        let tuningsDrivetrains = DriftRacerQuery.getTuningsDrivetrains(tunings)
        if (enginesDrivetrains.length === 1) {
            return enginesDrivetrains
        }
        if (tuningsDrivetrains.length === 1) {
            return tuningsDrivetrains
        }
        let nonUniqueTunings = DriftRacerQuery.getNonUniqueTunings(tunings)
        const uniqueTunings = tunings.filter(tuning => !nonUniqueTunings.includes(tuning))
        const nonUniqueTuningCarEngines: DriftEngineType[] = Array.from(new Set(
            DriftRacerDB
                .filter(car => nonUniqueTunings?.includes(car.tuning))
                .map(car => car.engine)
                .filter(engine => engines.includes(engine)
                )))
        const uniqueTuningDrivetrains = DriftRacerQuery.getTuningsDrivetrains(uniqueTunings)
        const nonUniqueTuningCarEnginesDrivetrains = DriftRacerQuery.getEnginesDrivetrains(nonUniqueTuningCarEngines, true)
        if (uniqueTuningDrivetrains.length === 1 && nonUniqueTuningCarEnginesDrivetrains.length === 1
            && uniqueTuningDrivetrains[0] === nonUniqueTuningCarEnginesDrivetrains[0]) {
            return [uniqueTuningDrivetrains[0]]
        }
        return Array.from(new Set([
            ...enginesDrivetrains,
            ...tuningsDrivetrains
        ]))
    }

    static filterEnginesByDrivetrain(engines: DriftEngineType[], drivetrain: DrivetrainType): DriftEngineType[] {
        if (engines.includes("any") || engines.length === 0) {
            engines = [...DRIFT_ENGINES]
            engines = engines.filter(engine => engine !== "any")
        }
        switch (drivetrain) {
            case "RWD":
                engines = engines.filter(engine => engine !== "porsche_992_carrera_4" && engine !== "porsche_992_carrera_4s")
                break
        }
        const drivetrainEngines = DriftRacerQuery.getDrivetrainEngines(drivetrain)
        return engines.filter(engine => drivetrainEngines.includes(engine))
    }

    static filterTuningsByDrivetrain(tunings: DriftTuningType[], drivetrain: DrivetrainType): DriftTuningType[] {
        if (tunings.includes("any") || tunings.length === 0) {
            tunings = [...DRIFT_TUNINGS]
            tunings = tunings.filter(tunings => tunings !== "any")
        }
        const drivetrainTunings = DriftRacerQuery.getDrivetrainTunings(drivetrain)
        return tunings.filter(tuning => drivetrainTunings.includes(tuning))
    }

    static isRWD(engine: DriftEngineType, tuning: DriftTuningType): boolean {
        tuning = DriftRacerQuery.normalizeTunings(tuning)
        if (tuning == "club") {
            return true
        }
        let tuningsDrivetrains = DriftRacerQuery.getTuningsDrivetrains([tuning])
        switch (engine) {
            case "porsche_992_carrera_4":
            case "porsche_992_carrera_4s":
            case "nissan_gtr_awd":
                return false
        }
        return tuningsDrivetrains.includes("RWD")
    }

    static isAWD(engine: DriftEngineType, tuning: DriftTuningType): boolean {
        tuning = DriftRacerQuery.normalizeTunings(tuning)
        if (tuning == "club") {
            return false
        }
        let tuningsDrivetrains = DriftRacerQuery.getTuningsDrivetrains([tuning])
        switch (engine) {
            case "porsche_992_carrera":
            case "porsche_992_carrera_s":
            case "nissan_gtr_rwd":
                return false
        }
        return tuningsDrivetrains.includes("AWD")
    }

    static isFWD(tuning: DriftTuningType): boolean {
        tuning = DriftRacerQuery.normalizeTunings(tuning)
        const availableDrivetrains = DriftRacerQuery.getTuningsDrivetrains([tuning])
        return availableDrivetrains.length == 1 && availableDrivetrains.includes("FWD")
    }

    static normalizeTunings(tuning: string): DriftTuningType {
        if (tuning.toLowerCase().indexOf("club") > -1) {
            return "club"
        }
        if (tuning.toLowerCase().indexOf("fai-gt1") > -1) {
            return "5,6L FIA-GT1 600 PS"
        }
        if (isDriftTuning(tuning)) {
            return tuning as DriftTuningType
        }
        return null
    }

    static isDrivetrain(requiredDrive: DrivetrainType[], engine: DriftEngineType, tuning: DriftTuningType): boolean {
        if (!requiredDrive || requiredDrive.includes("any")) {
            return true
        }
        if (requiredDrive.includes("RWD") && DriftRacerQuery.isRWD(engine, tuning)) {
            return true
        }
        if (requiredDrive.includes("AWD") && DriftRacerQuery.isAWD(engine, tuning)) {
            return true
        }
        if (requiredDrive.includes("FWD") && DriftRacerQuery.isFWD(tuning)) {
            return true
        }
        return false
    }

    static isTuningGroup(requiredTuningGroup: DriftTuningGroupType[], tuning: DriftTuningType): boolean {
        tuning = DriftRacerQuery.normalizeTunings(tuning)
        if (!requiredTuningGroup || requiredTuningGroup.includes("any")) {
            return true
        }
        return requiredTuningGroup.includes(DriftRacerDB.find(car => car.tuning == tuning)?.tuningGroup)
    }

    /* ----------------------------------------------------------------
 	 * PRIVATE METHODES
 	 * --------------------------------------------------------------*/

    private static getNonUniqueTunings(availableTunings?: DriftTuningType[]): DriftTuningType[] {
        const allNonUniqueTunings = Array.from(new Set(
            DriftRacerDB.filter(car => car.tuningNonUnique)?.map(car => car.tuning) || []))
        if (!availableTunings || availableTunings.includes("any") || availableTunings.length === 0) {
            return allNonUniqueTunings
        }
        return availableTunings.filter(tuning => allNonUniqueTunings.includes(tuning))
    }

    private static getEnginesDrivetrains(engines: DriftEngineType[], ignoreClubsport: boolean = false): DrivetrainType[] {
        if (!engines || engines.includes("any") || engines.length === 0) {
            engines = [...DRIFT_ENGINES]
            engines = engines.filter(engine => engine !== "any")
        }
        return Array.from(new Set(
            DriftRacerDB
                .filter(car => engines?.includes(car.engine))
                ?.filter(car => {
                    if (!ignoreClubsport) return true
                    return car.tuning !== "club"
                })
                ?.map(car => car.drivetrain)))
    }

    private static getTuningsDrivetrains(tunings: DriftTuningType[]): DrivetrainType[] {
        if (!tunings || tunings.includes("any") || tunings.length === 0) {
            tunings = [...DRIFT_TUNINGS]
            tunings = tunings.filter(tuning => tuning !== "any")
        }
        return Array.from(new Set(
            DriftRacerDB
                .filter(car => tunings.includes(car.tuning))
                .map(car => car.drivetrain)))
    }

    private static getDrivetrainEngines(drivetrain: DrivetrainType): DriftEngineType[] {
        switch (drivetrain) {
            case "any":
                return Array.from(new Set(
                    DriftRacerDB
                        .map(car => car.engine)))
            default:
                return Array.from(new Set(
                    DriftRacerDB
                        .filter(car => car.drivetrain == drivetrain)
                        .map(car => car.engine)))
        }
    }

    private static getDrivetrainTunings(drivetrain: DrivetrainType): DriftTuningType[] {
        switch (drivetrain) {
            case "any":
                return Array.from(new Set(
                    DriftRacerDB
                        .map(car => car.tuning)))
            default:
                return Array.from(new Set(
                    DriftRacerDB
                        .filter(car => car.drivetrain == drivetrain)
                        .map(car => car.tuning)))
        }
    }
}

import * as React from "react";
import {BasicPageTitle} from "../../../../ui/text/headings/BasicPageTitle";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {AchievementGroup} from "../../../../ui/achievements/group/AchievementGroup";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";
import {AchievementQuery} from "../../../../../../shared/achievements/AchievementQuery";
import {ACHIEVEMENT_GROUP_TYPES} from "../../../../../../shared/types/AchievementGroupType";
import {IAchievementData} from "../../../../../../shared/models/IAchievementData";
import {useEffect, useState} from "react";
import {useSocketSignals} from "../../../../hooks/useSocketSignals";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";

/******************************************************************
 * UserAchievementsPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserAchievementsPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {serverUserAchievementsUpdate} = useSocketSignals()
    const {routeOwnerNick, routeOwnerID} = useRouteOwner()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [userAchievements, setUserAchievements] = useState<IAchievementData[]>(null);
    const [isLoading, setIsLoading] = useState(true);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadUserAchievements()
    }, [serverUserAchievementsUpdate])

    /* ----------------------------------------------------------------
 	 * METHODS
 	 * --------------------------------------------------------------*/

    async function loadUserAchievements() {
        setIsLoading(true)
        const data = await api.achievement.getUserAchievements(routeOwnerID)
        setUserAchievements(data)
        setIsLoading(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="user-achievements-page"
            rowTemplate="min-content">
            <ContentLayout
                className="user-achievements-page-header"
                justifyItems="center"
                gap="none">
                <BasicPageTitle
                    title={dict("user.achievements.page.title")}
                    subTitle={routeOwnerNick}/>
                <InfoText
                    text={dict("user.achievements.page.info")}
                    style="abstract"
                    align="center"/>
            </ContentLayout>
            <Spacer height="small"/>
            <ComponentInitializer isPropertyAvailable={!isLoading}>
                {!isLoading &&
                    <ContentLayout>
                        {ACHIEVEMENT_GROUP_TYPES.map(groupType =>
                            <div key={groupType}>
                                <AchievementGroup
                                    title={dict("achievements.group." + groupType + ".title")}
                                    data={userAchievements}
                                    types={AchievementQuery.typesPerGroup(groupType, true)}/>
                                <Spacer height="tiny"/>
                            </div>)}
                    </ContentLayout>}
            </ComponentInitializer>
        </ContentLayout>
    );
}

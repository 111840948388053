/******************************************************************
 * AchievementGroupType
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export const ACHIEVEMENT_GROUP_TYPES = [
    "distance",
    "gymkhana"
] as const

export type AchievementGroupType = typeof ACHIEVEMENT_GROUP_TYPES[number];

import {FrontendServices} from "../core/FrontendServices";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {APIRoute} from "../../shared/routes/APIRoute";
import {IAchievementData} from "../../shared/models/IAchievementData";

/******************************************************************
 * AchievementAPI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AchievementAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async getUserAchievements(userID: MongoObjectIDType): Promise<IAchievementData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.ACHIEVEMENTS_USER, {id: userID}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }


}

import * as React from "react";
import {AchievementLevelType} from "../../../../../../shared/types/AchievementLevelType";

/******************************************************************
 * AchievementItemBackground
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementItemBackground(props: {
    level: AchievementLevelType
}) {

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function backgroundFill(): string {
        switch (props.level) {
            case "silver":
                return "url(#achievement-background-silver)";
            case "gold":
                return "url(#achievement-background-gold)";
            default:
                return null
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {backgroundFill() && <>
            <defs>
                <linearGradient
                    id="achievement-background-gold"
                    x1="28.9975"
                    y1="33.6141"
                    x2="82.7165"
                    y2="125.098"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F6C97F" stopOpacity="0.84"/>
                    <stop offset="1" stopColor="#4A370B"/>
                </linearGradient>
                <linearGradient
                    id="achievement-background-silver"
                    x1="16.9975"
                    y1="23.6141"
                    x2="70.7165"
                    y2="115.098"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9E9E9E"/>
                    <stop offset="1" stopColor="#171717"/>
                </linearGradient>
            </defs>
            <path
                d="M62.7233 10.5917L12.4641 40.4804V95.3128L21.9057 105.699L65.9546 127.521L114.625 99.4501L116.442 40.4804L62.7233 10.5917Z"
                fill={backgroundFill()}/>
        </>}
    </>
}

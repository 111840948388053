import {IGroupData} from "../models/IGroupData";
import {IUserData} from "../models/IUserData";
import {IGroupMemberData} from "../models/submodels/IGroupMemberData";
import {DriverLicenseUtils} from "./DriverLicenseUtils";

/******************************************************************
 * GroupUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class GroupUtils {

    static isAdmin(group: IGroupData, user: IUserData): boolean {
        if (!group) return false;
        if (!group.members) return false;
        if (!user) return false;
        if (!user._id) return false;
        return group.members.filter(member => {
            return (member.role == "admin" || member.role == "owner")
                && member.user?._id.toString() == user._id.toString()
        }).length > 0
    }

    static isMember(group: IGroupData, user: IUserData): boolean {
        if (!group) return false;
        if (!group.members) return false;
        if (!user) return false;
        if (!user._id) return false;
        return group.members.filter(member => {
            return member.user._id.toString() == user._id.toString()
        }).length > 0
    }

    static isMemberAllowedToRemoveSelfFromGroup(group: IGroupData, user: IUserData, bodyObject: any): boolean {
        return GroupUtils.isMember(group, user)
        && GroupUtils.hasOnlyMembersAndGroupID(bodyObject)
        && GroupUtils.onlyMissingSelfInNewMembers(group, user, bodyObject.members)
    }

    static hasOnlyMembersAndGroupID(obj: any): boolean {
        const keys = Object.keys(obj);
        return keys.every(key => key === 'members'
            || key === 'groupID'
            || key === 'ownerID');
    }

    static getContact(group: IGroupData): IUserData {
        if (!group) return null
        if (group.contact?._id) return group.contact
        const customContact = group.members?.find(member => (member.user?._id?.toString() ?? member.user?.toString()) == group.contact)?.user
        if (customContact) return customContact
        return group.owner
    }

    static showContactButton(group: IGroupData, authUserData: IUserData): boolean {
        if (!group) return false
        if (!authUserData) return false
        if (!group.contact) return false
        if ((group.contact._id ?? group.contact) == authUserData._id) return false
        if (group.privacy === "public") return true
        return GroupUtils.isMember(group, authUserData)
    }

    static onlyMissingSelfInNewMembers(group: IGroupData, user: IUserData, newMembers: IGroupMemberData[]): boolean {
        if (!group || !group.members) return false;
        if (group.members.length > 1 && group.members.length == newMembers.length + 1) {
            const userMemberID = group.members.filter(member => member.user._id.toString() == user._id.toString())[0]?._id?.toString();
            if (!userMemberID) return false;
            return newMembers.filter(member => member._id.toString() == userMemberID.toString()).length == 0;
        }
        return false
    }

    static getMemberWithHighestSubscription(group: IGroupData): IUserData {
        if (!group || !group.members) return null;
        const membersWithSubscription = group.members.filter(member => member.user?.subscription);
        if (membersWithSubscription.length == 0) return null;
        if (membersWithSubscription.length == 1) return membersWithSubscription[0].user;
        const premiumUser: IUserData = membersWithSubscription.find(member => DriverLicenseUtils.validLicenseType(member.user?.subscription) == "premium")?.user;
        if (premiumUser) return premiumUser;
        const basicUser: IUserData = membersWithSubscription.find(member => DriverLicenseUtils.validLicenseType(member.user?.subscription) == "basic")?.user;
        if (basicUser) return basicUser;
        return membersWithSubscription[0].user;
    }

    static hasMembersWithNoSubscription(group: IGroupData): boolean {
        if (!group || !group.members) return false;
        return group.members.filter(member => !member.user?.subscription?.subscriptionID).length > 0
    }
}

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {AchievementLevelType} from "../../../../../shared/types/AchievementLevelType";
import {AchievementItemBackground} from "./assets/AchievementItemBackground";
import {AchievementItemOutlines} from "./assets/AchievementItemOutlines";
import {AchievementItemIcon} from "./assets/AchievementItemIcon";
import {AchievementItemStar} from "./assets/AchievementItemStar";
import {AchievementType} from "../../../../../shared/types/AchievementType";
import {AchievementItemLabel} from "./assets/AchievementItemLabel";
import {AchievementInfoModal} from "../../../systems/modals/achievement/AchievementInfoModal";
import {AchievementQuery} from "../../../../../shared/achievements/AchievementQuery";
import {useServices} from "../../../hooks/useServices";
import {Divider} from "../../utils/divider/Divider";
import {Spacer} from "../../utils/spacer/Spacer";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {LabelValueText} from "../../text/labelvalue/LabelValueText";
import {Tooltip} from "../../tooltip/Tooltip";
import {useWindowSize} from "../../../hooks/useWindowSize";

/******************************************************************
 * AchievementItem
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementItem(props: {
    type: AchievementType
    value?: number
    level?: AchievementLevelType
    size?: "small" | "medium" | "normal" | "huge"
    showValueInfo?: boolean
    clickable?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {windowWidth} = useWindowSize()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showInfo, setShowInfo] = useState(false);
    const [size, setSize] = useState(props.size ?? "normal");
    const [normalWidth] = useState(127);
    const [normalHeight] = useState(174);
    const [width, setWidth] = useState(normalWidth);
    const [height, setHeight] = useState(normalHeight);

    /* ----------------------------------------------------------------
 	 * USE EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        switch (size) {
            case "small":
                setWidth(127 * 0.5)
                setHeight(174 * 0.5)
                break
            case "medium":
                setWidth(127 * 0.75)
                setHeight(174 * 0.75)
                break
            case "normal":
                setWidth(127)
                setHeight(174)
                break
            case "huge":
                let scale = 2.5
                if (windowWidth < 600) scale = 2.25
                if (windowWidth < 400) scale = 2
                setWidth(127 * scale)
                setHeight(174 * scale)
                break
        }
    }, [size, windowWidth])

    useEffect(() => {
        setSize(props.size ?? "normal")
    }, [props.size])

    /* ----------------------------------------------------------------
 	 * METHODS
 	 * --------------------------------------------------------------*/

    function level(): {
        type: AchievementLevelType
        bronzeProgress: number
        silverProgress: number
        goldProgress: number
    } {
        return AchievementQuery.computeLevelAndProgress(props.type, props.value, props.level)
    }

    function valueText(): string {
        const definition = AchievementQuery.definitionByType(props.type)
        const value = props.value ?? 0
        const property = definition.property
        return dict(`achievements.valueInfo.${property}.value`)
            .replaceAll("{VALUE}", value.toLocaleString())
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <div
            ref={rootRef}
            className="achievement-item"
            data-clickable={props.clickable}
            onClick={() => setShowInfo(true)}>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${normalWidth} ${normalHeight}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <AchievementItemBackground level={level().type}/>
                <AchievementItemOutlines level={level().type}/>
                <AchievementItemIcon type={props.type} level={level().type}/>
                <AchievementItemStar progress={level().bronzeProgress} x={12} y={126}/>
                <AchievementItemStar progress={level().silverProgress} x={47} y={140}/>
                <AchievementItemStar progress={level().goldProgress} x={82} y={126}/>
                <AchievementItemLabel type={props.type}/>
            </svg>
            {props.showValueInfo &&
                <ContentLayout gap="small">
                    <Spacer height="small"/>
                    <Divider style="modal"/>
                    <LabelValueText
                        className="achievement-item-value-info"
                        label={dict("achievements.valueInfo.label")}
                        value={valueText()}
                        align="center"
                        size="large"/>
                    <Divider style="modal"/>
                </ContentLayout>}
        </div>
        <Tooltip
            label={valueText()}
            hoverElement={rootRef.current}/>
        {showInfo &&
            <AchievementInfoModal
                type={props.type}
                value={props.value}
                onRequestClose={() => setShowInfo(false)}/>}
    </>

}

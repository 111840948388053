import {DriftRacerType} from "../types/DriftRacerType";

/******************************************************************
 * DriftRacerDB
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export const DriftRacerDB: DriftRacerType[] = [
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "BASIC SETUP 550 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "PERFORMANCE-KIT 700 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "SUPERCHARGER 920 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "4-WHEEL BASIC 550 PS",
        tuningGroup: "basic",
        drivetrain: "AWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "4-WHEEL DRIVE 700 PS",
        tuningGroup: "performance",
        drivetrain: "AWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "4-WHEEL DRIVE 920 PS",
        tuningGroup: "max",
        drivetrain: "AWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "FWD 300 PS",
        tuningGroup: "basic",
        drivetrain: "FWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V8",
        tuning: "FWD 550 PS",
        tuningGroup: "basic",
        drivetrain: "FWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "BASIC SETUP 565 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "DTE-CHIPTUNING 750 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "SINGLE-TURBO 1000 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "4-WHEEL BASIC 565 PS",
        tuningGroup: "basic",
        drivetrain: "AWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "4-WHEEL DRIVE 750 PS",
        tuningGroup: "performance",
        drivetrain: "AWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "4-WHEEL DRIVE 1000 PS",
        tuningGroup: "max",
        drivetrain: "AWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "FWD 303 PS",
        tuningGroup: "basic",
        drivetrain: "FWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V6",
        tuning: "FWD 565 PS",
        tuningGroup: "basic",
        drivetrain: "FWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V12",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V12",
        tuning: "BASIC SETUP 533 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V12",
        tuning: "PERFORMANCE-KIT 710 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V12",
        tuning: "HIGH RPM BOOST 980 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V12",
        tuning: "4-WHEEL BASIC 533 PS",
        tuningGroup: "basic",
        drivetrain: "AWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V12",
        tuning: "4-WHEEL DRIVE 710 PS",
        tuningGroup: "performance",
        drivetrain: "AWD"
    },
    {
        model: "D1",
        vendor: "d1",
        engine: "V12",
        tuning: "4-WHEEL DRIVE 980 PS",
        tuningGroup: "max",
        drivetrain: "AWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_r416v_stock",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_r416v_stock",
        tuning: "BASIC SETUP 280 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_r416v_stock",
        tuning: "TUNED 350 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_r416v_dtm",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_r416v_dtm",
        tuning: "BASIC SETUP 373 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_r416v_dtm",
        tuning: "RACE SETUP 390 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_v6",
        tuning: "BASIC SETUP 450 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_v6",
        tuning: "DTE-CHIPTUNING 633 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_v6",
        tuning: "SINGLE-TURBO 800 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_v8",
        tuning: "BASIC SETUP 440 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_v8",
        tuning: "PERFORMANCE-KIT 620 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Mercedes 190 Evo II",
        vendor: "mercedes",
        engine: "mercedes_v8",
        tuning: "SUPERCHARGER 780 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_r416v_stock",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_r416v_stock",
        tuning: "BASIC SETUP 275 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_r416v_stock",
        tuning: "TUNED 320 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_r416v_dtm",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_r416v_dtm",
        tuning: "BASIC SETUP 375 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_r416v_dtm",
        tuning: "RACE SETUP 390 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_v6",
        tuning: "BASIC SETUP 450 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_v6",
        tuning: "DTE-CHIPTUNING 633 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_v6",
        tuning: "SINGLE-TURBO 800 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_v8",
        tuning: "BASIC SETUP 440 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_v8",
        tuning: "PERFORMANCE-KIT 620 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30",
        vendor: "bmw",
        engine: "bmw_v8",
        tuning: "SUPERCHARGER 780 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_mahle",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_mahle",
        tuning: "SINGLE-TURBO 850 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_mahle",
        tuning: "SINGLE-TURBO 950 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_r416v_dtm",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_r416v_dtm",
        tuning: "BASIC SETUP 375 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_r416v_dtm",
        tuning: "RACE SETUP 390 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_v8",
        tuning: "BASIC SETUP 440 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_v8",
        tuning: "PERFORMANCE-KIT 620 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "BMW E30 Mahle",
        vendor: "bmw",
        engine: "bmw_v8",
        tuning: "SUPERCHARGER 780 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera",
        tuning: "BASIC",
        tuningGroup: "basic",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera",
        tuning: "SPORT CHRONO-PAKET",
        tuningGroup: "performance",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_s",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_s",
        tuning: "BASIC",
        tuningGroup: "basic",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_s",
        tuning: "SPORT CHRONO-PAKET",
        tuningGroup: "performance",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_s",
        tuning: "Turbo S",
        tuningGroup: "max",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_4",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD",
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_4",
        tuning: "BASIC",
        tuningGroup: "basic",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_4",
        tuning: "SPORT CHRONO-PAKET",
        tuningGroup: "performance",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_4",
        tuning: "Turbo S",
        tuningGroup: "max",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_4s",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD",
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_4s",
        tuning: "BASIC",
        tuningGroup: "basic",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_4s",
        tuning: "SPORT CHRONO-PAKET",
        tuningGroup: "performance",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Porsche 911 (992)",
        vendor: "porsche",
        engine: "porsche_992_carrera_4s",
        tuning: "Turbo S",
        tuningGroup: "max",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_rwd",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_rwd",
        tuning: "BASIC 550 PS",
        tuningGroup: "basic",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_rwd",
        tuning: "NISMO TUNED 600 PS",
        tuningGroup: "performance",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_rwd",
        tuning: "TURBO UPGRADE 700 PS",
        tuningGroup: "performance",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_rwd",
        tuning: "WORLD RECORD 1190 PS",
        tuningGroup: "max",
        drivetrain: "RWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_awd",
        tuning: "BASIC 550 PS",
        tuningGroup: "basic",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_awd",
        tuning: "NISMO TUNED 600 PS",
        tuningGroup: "performance",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_awd",
        tuning: "TURBO UPGRADE 700 PS",
        tuningGroup: "performance",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_gtr_awd",
        tuning: "WORLD RECORD 1190 PS",
        tuningGroup: "max",
        drivetrain: "AWD",
        tuningNonUnique: true
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_v8_rwd",
        tuning: "5,2L 480 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_v8_rwd",
        tuning: "5,2L 510 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_v8_rwd",
        tuning: "5,6L FIA-GT1 600 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_v8_rwd",
        tuning: "5,6L TWIN TURBO 650 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Nissan GT-R (35)",
        vendor: "nissan",
        engine: "nissan_v8_rwd",
        tuning: "5,6L BIG BOOST 1000 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "WRX STi",
        tuningGroup: "basic",
        drivetrain: "AWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "WRC",
        tuningGroup: "performance",
        drivetrain: "AWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "Stage II",
        tuningGroup: "performance",
        drivetrain: "AWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "Stage III",
        tuningGroup: "max",
        drivetrain: "AWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "STi RWD",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "WRC RWD",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "Stage II RWD",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_sti",
        tuning: "Stage III RWD",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_v8",
        tuning: "4,0L 340 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_v8",
        tuning: "6,2L 460 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_v8",
        tuning: "6,2L 550 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_v8",
        tuning: "6,2L 780 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_2jz",
        tuning: "Stage I - 380 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_2jz",
        tuning: "Stage II - 533 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_2jz",
        tuning: "Stage III - 670 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Subaru Impreza WRC97",
        vendor: "subaru",
        engine: "subaru_impreza_2jz",
        tuning: "Stage IV - 930 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "club",
        tuningGroup: "clubsport",
        drivetrain: "RWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "Gr.4 - 320 PS",
        tuningGroup: "basic",
        drivetrain: "AWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "Gr.B - 360 PS",
        tuningGroup: "performance",
        drivetrain: "AWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "20V - 500 PS",
        tuningGroup: "performance",
        drivetrain: "AWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "20V - 700 PS",
        tuningGroup: "max",
        drivetrain: "AWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "RWD - 320 PS",
        tuningGroup: "basic",
        drivetrain: "RWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "RWD - 360 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "RWD - 500 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Audi quattro",
        vendor: "audi",
        engine: "audi_quattro",
        tuning: "RWD - 700 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "Audi V8 RWD",
        vendor: "audi",
        engine: "audi_v8_rwd",
        tuning: "4,0L 340 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Audi V8 RWD",
        vendor: "audi",
        engine: "audi_v8_rwd",
        tuning: "6,2L 460 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Audi V8 RWD",
        vendor: "audi",
        engine: "audi_v8_rwd",
        tuning: "6,2L 550 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Audi V8 RWD",
        vendor: "audi",
        engine: "audi_v8_rwd",
        tuning: "6,2L 780 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    },
    {
        model: "Audi V12 RWD",
        vendor: "audi",
        engine: "audi_v12_rwd",
        tuning: "4,0L 355 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Audi V12 RWD",
        vendor: "audi",
        engine: "audi_v12_rwd",
        tuning: "5,2L 420 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Audi V12 RWD",
        vendor: "audi",
        engine: "audi_v12_rwd",
        tuning: "5,2L 455 PS",
        tuningGroup: "performance",
        drivetrain: "RWD"
    },
    {
        model: "Audi V12 RWD",
        vendor: "audi",
        engine: "audi_v12_rwd",
        tuning: "4,8L 710 PS",
        tuningGroup: "max",
        drivetrain: "RWD"
    }
]

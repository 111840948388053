import * as React from "react";
import {ApprovalStateType} from "../../../../../shared/types/ApprovalStateType";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Icon} from "../../icons/Icon";

/******************************************************************
 * ApprovalStateIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ApprovalStateIcon(props: {
    state: ApprovalStateType
    scale?: number
    hasVideo?: boolean
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="approval-state-icon"
            alignItems="center"
            justifyItems="center">
            {(props.state == "pending" || !props.state) &&
                <Icon
                    type="pending"
                    iconColor="yellow"
                    scale={props.scale}
                    opacity={props.hasVideo ? null : 0.25}/>}
            {props.state == "approved" &&
                <Icon
                    type="save"
                    iconColor="green"
                    scale={props.scale}/>}
            {props.state == "rejected" &&
                <Icon
                    type="cancel"
                    iconColor="red"
                    scale={props.scale}/>}
        </ContentLayout>
    );

}

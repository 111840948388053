import * as React from "react";
import {useEffect, useState} from "react";
import {ActionBar} from "../../bar/ActionBar";
import {ArticleBlockType, IArticleBlockData, IArticleData} from "../../../../../shared/models/IArticleData";
import {ArticleAddBlockContextMenu} from "../../context/article/ArticleAddBlockContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ArticleUtils} from "../../../../../shared/utils/ArticleUtils";
import {IconButton} from "../../buttons/icon/IconButton";
import {Divider} from "../../utils/divider/Divider";
import {InlineDeleteAction} from "../../form/inlineaction/delete/InlineDeleteAction";

/******************************************************************
 * ArticleBlockActionBar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleBlockActionBar(props: {
    article: IArticleData
    block: IArticleBlockData
    availableBlocks: ArticleBlockType[]
    onChange: () => void
    onToggleAddMenu?: (isOpen: boolean) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, state} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [showEdits, setShowEdits] = useState<boolean>(false)
    const [isHelpArticle, setIsHelpArticle] = useState<boolean>(ArticleUtils.isHelpArticle(props.article))

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setShowEdits(props.article?.blocks?.length > 0)
        setIsHelpArticle(ArticleUtils.isHelpArticle(props.article))
    }, [props.article, props.block])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function addBlock(blockType: ArticleBlockType) {
        let block: IArticleBlockData = {}
        switch (blockType) {
            case "image":
                block.image = {
                    file: null,
                    caption: null,
                    alt: null
                }
                break;
            case "youtube":
                block.youtube = {
                    videoID: null
                }
                break;
            case "text":
                block.text = {
                    text: null
                }
            case "link":
                block.link = {
                    articleID: null
                }
                break;
            case "hero":
                block.hero = {
                    title: null,
                    abstract: null,
                    file: null,
                    alt: null
                }
                break;
            case "feature":
                block.feature = {
                    title: null,
                    abstract: null,
                    file: null,
                    alt: null,
                    articleID: null
                }
                break;
            case "testimonial":
                block.testimonial = {
                    user: null,
                    message: null
                }
                break;
            case "achievement":
                block.achievement = {
                    type: null,
                    level: null,
                    task: null
                }
                break;
            default:
                console.log("ArticleBlockType not supported:", blockType)
                return
        }
        const index = props.article.blocks.indexOf(props.block)
        props.article.blocks.splice(index + 1, 0, block)
        await api.article.updateArticle(props.article._id, props.article)
        props.onChange()
    }

    async function deleteBlock() {
        props.article.blocks.splice(props.article.blocks.indexOf(props.block), 1)
        await api.article.updateArticle(props.article._id, props.article)
        props.onChange()
    }

    async function moveBlockUp() {
        const index = props.article.blocks.indexOf(props.block)
        const block = props.article.blocks.splice(index, 1)[0]
        props.article.blocks.splice(index - 1, 0, block)
        await api.article.updateArticle(props.article._id, props.article)
        props.onChange()
    }

    async function moveBlockDown() {
        const index = props.article.blocks.indexOf(props.block)
        const block = props.article.blocks.splice(index, 1)[0]
        props.article.blocks.splice(index + 1, 0, block)
        await api.article.updateArticle(props.article._id, props.article)
        props.onChange()
    }

    async function toggleVisibility() {
        props.block.hidden = !props.block.hidden
        await api.article.updateArticle(props.article._id, props.article)
        props.onChange()
    }

    function isMarkdownBlock() {
        return props.block?.text || props.block?.image
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ActionBar location="article-block">
            <ArticleAddBlockContextMenu
                availableBlocks={props.availableBlocks}
                onToggle={props.onToggleAddMenu}
                onAddBlock={addBlock}/>
            {showEdits && <>
                {isMarkdownBlock() && !isHelpArticle && <>
                    <Divider style="action-bar"/>
                    <IconButton
                        type="help"
                        size="small"
                        scale={0.8}
                        onClick={() => state.showHelp.setValue("article.markdown")}/>
                </>}
                <Divider style="action-bar"/>
                <IconButton
                    type={props.block.hidden ? "hidden" : "visible"}
                    size="small"
                    onClick={toggleVisibility}/>
                <Divider style="action-bar"/>
                <IconButton
                    type="up"
                    size="small"
                    disabled={props.article.blocks?.[0]?._id === props.block?._id}
                    onClick={moveBlockUp}/>
                <IconButton
                    type="down"
                    size="small"
                    disabled={props.article.blocks?.[props.article.blocks.length - 1]?._id === props.block?._id}
                    onClick={moveBlockDown}/>
                <Divider style="action-bar"/>
                <InlineDeleteAction
                    isBusy={false}
                    size="small"
                    onSave={deleteBlock}/>
            </>}
        </ActionBar>
    );

}

/******************************************************************
 * DriftEngineType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const DRIFT_ENGINES = [
    "any",
    "V8",
    "V6",
    "V12",
    "bmw_r416v_dtm",
    "bmw_r416v_stock",
    "bmw_v6",
    "bmw_v8",
    "bmw_mahle",
    "mercedes_r416v_dtm",
    "mercedes_r416v_stock",
    "mercedes_v6",
    "mercedes_v8",
    "porsche_992_carrera",
    "porsche_992_carrera_s",
    "porsche_992_carrera_4",
    "porsche_992_carrera_4s",
    "nissan_gtr_rwd",
    "nissan_gtr_awd",
    "nissan_v8_rwd",
    "subaru_impreza_2jz",
    "subaru_impreza_v8",
    "subaru_impreza_sti",
    "audi_quattro",
    "audi_v8_rwd",
    "audi_v12_rwd"
] as const

export type DriftEngineType = typeof DRIFT_ENGINES[number];

export function isDriftEngine(engine: string): boolean {
    return DRIFT_ENGINES.includes(engine as DriftEngineType)
}

/******************************************************************
 * APIRoute
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class APIRoute {

    static readonly USER_SIGNUP = "/api/user/signup"
    static readonly USER_LOGIN = "/api/user/login"
    static readonly USER_LOGOUT_FROM_ALL_DEVICES = "/api/user/logoutfromalldevices";
    static readonly USER_REQUEST_VERIFICATION_CODE = "/api/user/requestcode"
    static readonly USER_CHANGE_PASSWORD = "/api/user/changepassword"
    static readonly USER_VERIFY_ACCOUNT = "/api/user/verify"
    static readonly USER_AVATAR = "/api/user/avatar"
    static readonly USER_CAPI_PASSWORD = "/api/user/capipassword"
    static readonly USER = "/api/user"
    static readonly USERS = "/api/users"
    static readonly USER_ADD_MAIL_TO_WHITELIST = "/api/user/addmailtowhitelist"
    static readonly AUTH_USER = "/api/user/auth"
    static readonly AUTH_USER_REFRESH_SUBSCRIPTION = "/api/user/auth/refreshsubscription"
    static readonly USER_REFRESH_SUBSCRIPTION = "/api/user/refreshsubscription"
    static readonly AUTH_USER_LEGAL_VERSION_CHECK = "/api/info/legalversioncheck";
    static readonly AUTH_USER_SEEN_SERVERVERSION_RELEASE_NOTE = "/api/user/seenlatestreleasenotes";
    static readonly AUTH_USER_SERVER_VERSION_RELEASE_NOTE_ARTICLE_ID = "/api/user/serverversionreleasenotearticleid";

    static readonly DICTIONARY = "/api/dictionary"
    static readonly DICTIONARY_ENTRY = "/api/dictionary/entry"
    static readonly DICTIONARY_ENTRY_TRANSLATE = "/api/dictionary/entry/translate"

    static readonly SETTING = "/api/setting"

    static readonly INFO_CHANGELOG = "/api/system/changelog"
    static readonly INFO_SERVER_VERSION = "/api/info/serverversion";
    static readonly INFO_SERVER_TIME = "/api/info/servertime";
    static readonly INFO_FRONTEND_ENV_VARS = "/api/info/frontendenvvars";

    static readonly EVENT = "/api/event"
    static readonly EVENT_CAN_CREATE = "/api/event/cancreate"
    static readonly EVENTS_CREATED = "/api/events/created"
    static readonly EVENTS_JOINED = "/api/events/joined"
    static readonly EVENT_HERO_UPLOAD = "/api/event/heroimage"
    static readonly EVENT_CHILDREN = "/api/event/children"
    static readonly EVENT_CHILDREN_FOLDER = "/api/event/children/folder"
    static readonly EVENT_CHILDREN_ORDER = "/api/event/children/order"
    static readonly EVENT_DRIVERS_DRIVEN = "/api/event/drivers/driven"
    static readonly EVENT_DRIVER_REQUEST = "/api/event/driver/request"
    static readonly EVENT_DRIVER_PENDING = "/api/event/driver/pending"
    static readonly EVENT_DRIVER = "/api/event/driver"
    static readonly EVENTS_FINDER = "/api/events/finder";
    static readonly EVENTS_PUBLIC_LIVE = "/api/events/public/live";

    static readonly SESSION = "/api/session"
    static readonly SESSION_RESET = "/api/session/reset"
    static readonly SESSION_LOG = "/api/session/log";
    static readonly SESSION_ROUTE = "/api/session/path"
    static readonly SESSION_FOLLOW_SESSION = "/api/session/followsession";

    static readonly STINT = "/api/stint";
    static readonly STINTS = "/api/stints";
    static readonly STINTS_BY_SESSION_AND_USERS = "/api/stintsBySessionAndUsers";

    static readonly RESULT = "/api/result"
    static readonly RESULT_SESSIONS = "/api/result/sessions"
    static readonly RESULT_SCOREBOARD = "/api/result/scoreboard";
    static readonly RESULTS_CONTAINING_SESSION = "/api/results/containingsession";

    static readonly LEADERBOARD_SESSION = "/api/leaderboard/session"
    static readonly LEADERBOARD_TRACK_FILTER = "/api/leaderboard/trackfilter"
    static readonly LEADERBOARD_REBUILD = "/api/leaderboard/rebuild"
    static readonly LEADERBOARD_ENTRY = "/api/leaderboard/entry"
    static readonly LEADERBOARD_ENTRY_REBUILD = "/api/leaderboard/entry/rebuild"
    static readonly LEADERBOARD_ENTRY_END = "/api/leaderboard/entry/end"

    static readonly TRACK = "/api/track"
    static readonly TRACKS = "/api/tracks"
    static readonly TRACK_IMAGE = "/api/track/image"
    static readonly TRACK_INSTRUCTION_IMAGE = "/api/track/instructionimage"
    static readonly TRACK_FILTER_PREVIEW = "/api/track/filterpreview";
    static readonly TRACK_USED = "/api/track/used";
    static readonly TRACK_CAN_CREATE: string = "/api/track/cancreate";

    static readonly GARAGE = "/api/garage"
    static readonly CAR = "/api/car"
    static readonly CAR_IMAGE = "/api/car/image"
    static readonly CAR_CAN_CREATE: string = "/api/car/cancreate";

    static readonly FILE_IMAGE = "/api/file/image"
    static readonly FILE = "/api/file";
    static readonly FILE_OG_IMAGE = "/api/file/ogimage";

    static readonly GROUP = "/api/group";
    static readonly GROUP_AVATAR = "/api/group/avatar"
    static readonly GROUPS = "/api/groups"
    static readonly GROUP_CAN_CREATE: string = "/api/group/cancreate";

    static readonly ADMIN_DB_TRANSFER_DICTIONARY = "/api/admin/db/transfer/dictionary"
    static readonly ADMIN_DB_BACKUPS = "/api/admin/db/backups";
    static readonly ADMIN_DB_BACKUP_RESTORE = "/api/admin/db/backup/restore";
    static readonly ADMIN_DB_BACKUP_CREATE = "/api/admin/db/backup/create";
    static readonly ADMIN_DB_BACKUP = "/api/admin/db/backup";
    static readonly ADMIN_DB_INSTALL_MONGO_DB_TOOLS = "/api/admin/db/install/mongodbtools";
    static readonly ADMIN_DB_INFO = "/api/admin/db/info";
    static readonly ADMIN_ENV_VARS = "/api/admin/envvars";
    static readonly ADMIN_SERVER_CLEAR_DOCUMENT_CACHES = "/api/admin/server/clearDocumentCaches";
    static readonly ADMIN_SERVER_CLEAR_LEADERBOARD_CACHES = "/api/admin/server/clearLeaderboardCaches";
    static readonly ADMIN_SERVER_CLEAR_CAPI_QUEUES = "/api/admin/server/clearCapiQueues";
    static readonly ADMIN_SERVER_CLEAR_USER_CAPI_QUEUE = "/api/admin/server/clearUserCapiQueue";
    static readonly ADMIN_SERVER_CACHE_INFO = "/api/admin/server/cacheinfo";
    static readonly ADMIN_DB_MASTER_ARTICLES: string = "/api/admin/db/master/articles";
    static readonly ADMIN_DB_LOCAL_TO_MASTER_ARTICLE: string = "/api/admin/db/localtomaster/article";
    static readonly ADMIN_DB_MASTER_TO_LOCAL_ARTICLE: string = "/api/admin/db/mastertolocal/article";

    static readonly LOG = "/api/log";
    static readonly LOG_HISTORY = "/api/log/loghistory";
    static readonly HISTORY_LOG = "/api/log/historylog";

    static readonly ADMIN_THROW_EXCEPTION = "/api/admin/throwexception";
    static readonly PRESET_SESSION = "/api/preset/session";
    static readonly PRESETS_SESSION = "/api/presets/session";

    static readonly PROXY_YOUTUBE_THUMBNAIL = "/api/proxy/youtubethumbnail";

    static readonly ARTICLE = "/api/article";
    static readonly ARTICLES = "/api/articles";
    static readonly ARTICLE_COPY_CONTENT = "/api/article/copycontent";
    static readonly ARTICLE_RELEASE_NOTES = "/api/article/releasenotes";

    static readonly MAIL = "/api/mail";
    static readonly MAILS = "/api/mails";
    static readonly MAIL_RENDER = "/api/mail/render";
    static readonly MAIL_TEST = "/api/mail/test";
    static readonly MAILING_SEND = "/api/mailing/send";
    static readonly NEWSLETTER_MAILS = "/api/newsletter/mails";

    static readonly ACADEMY_CHAPTERS = "/api/academy/chapters";
    static readonly ACADEMY_CHAPTER = "/api/academy/chapter";

    static readonly TRANSLATE = "/api/translate";

    static readonly CONTACT = "/api/contact";

    static readonly PADDLE_DRIVERLICENSE_PLANS = "/api/paddle/driverlicenses";
    static readonly PADDLE_PREVIEW_SUBSCRIPTION = "/api/paddle/previewSubscription";
    static readonly PADDLE_UPDATE_SUBSCRIPTION = "/api/paddle/updateSubscription";
    static readonly PADDLE_CANCEL_SUBSCRIPTION = "/api/paddle/cancelSubscription";
    static readonly PADDLE_WEBHOOK = "/api/paddle/webhook";
    static readonly PADDLE_CUSTOMER_ADDRESS = "/api/paddle/customer/address";
    static readonly PADDLE_SUBSCRIPTION_TRANSACTION_ID = "/api/paddle/subscription/transactionid";

    static readonly ACHIEVEMENTS_USER = "/api/achievements/user";

}

import {IAchievementDefinition} from "./IAchievementDefinition";

/******************************************************************
 * AchievementDefinitions
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export const AchievementDefinitions: IAchievementDefinition[] = [
    {
        type: "distanceTotal",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 300,
        },
        silver: {
            value: 3000,
        },
        gold: {
            value: 30000,
        },
        capiEndHook: {
            drivetrain: ["any"]
        }
    },
    {
        type: "distanceRWD",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 100,
        },
        silver: {
            value: 1000,
        },
        gold: {
            value: 10000,
        },
        capiEndHook: {
            drivetrain: ["RWD"]
        }
    },
    {
        type: "distanceAWD",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 100,
        },
        silver: {
            value: 1000,
        },
        gold: {
            value: 10000,
        },
        capiEndHook: {
            drivetrain: ["AWD"]
        }
    },
    {
        type: "distanceFWD",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 40,
        },
        silver: {
            value: 400,
        },
        gold: {
            value: 4000,
        },
        capiEndHook: {
            drivetrain: ["FWD"]
        }
    },
    {
        type: "distanceMaxTuning",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 80,
        },
        silver: {
            value: 800,
        },
        gold: {
            value: 8000,
        },
        capiEndHook: {
            tuningGroup: ["max"],
        }
    },
    {
        type: "distancePerformanceTuning",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 80,
        },
        silver: {
            value: 800,
        },
        gold: {
            value: 8000,
        },
        capiEndHook: {
            tuningGroup: ["performance"],
        }
    },
    {
        type: "distanceBasicTuning",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 80,
        },
        silver: {
            value: 800,
        },
        gold: {
            value: 8000,
        },
        capiEndHook: {
            tuningGroup: ["basic"],
        }
    },
    {
        type: "distanceClubsportTuning",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 60,
        },
        silver: {
            value: 600,
        },
        gold: {
            value: 6000,
        },
        capiEndHook: {
            tuningGroup: ["clubsport"],
        }
    },
    {
        type: "distanceRainTrack",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 40,
        },
        silver: {
            value: 400,
        },
        gold: {
            value: 4000,
        },
        capiEndHook: {
            trackBundle: ["none"],
            trackCondition: ["drift_asphalt_wet"]
        }
    },
    {
        type: "distanceGravelTrack",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 40,
        },
        silver: {
            value: 400,
        },
        gold: {
            value: 4000,
        },
        capiEndHook: {
            trackBundle: ["none"],
            trackCondition: ["drift_dirt"]
        }
    },
    {
        type: "distanceIceTrack",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 20,
        },
        silver: {
            value: 200,
        },
        gold: {
            value: 2000,
        },
        capiEndHook: {
            trackBundle: ["none"],
            trackCondition: ["drift_ice"]
        }
    },
    {
        type: "distanceAsphaltTrack",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 120,
        },
        silver: {
            value: 1200,
        },
        gold: {
            value: 12000,
        },
        capiEndHook: {
            trackBundle: ["none"],
            trackCondition: ["drift_asphalt"]
        }
    },
    {
        type: "distanceRally",
        group: "distance",
        property: "drivenDistance",
        bronze: {
            value: 50,
        },
        silver: {
            value: 500,
        },
        gold: {
            value: 5000,
        },
        capiEndHook: {
            trackBundle: ["rally", "rally_cross"]
        }
    },
    {
        type: "gymkhanaTotal",
        group: "gymkhana",
        property: "sumScore",
        bronze: {
            value: 300000,
        },
        silver: {
            value: 3000000,
        },
        gold: {
            value: 30000000,
        },
        capiEndHook: {
            sessionGameMode: ["gymkhana"]
        }
    },
    {
        type: "gymkhanaMaxTuning",
        group: "gymkhana",
        property: "maxScore",
        bronze: {
            value: 10000,
        },
        silver: {
            value: 25000,
        },
        gold: {
            value: 30000,
        },
        capiEndHook: {
            sessionGameMode: ["gymkhana"],
            tuningGroup: ["max"]
        }
    },
    {
        type: "gymkhanaPerformanceTuning",
        group: "gymkhana",
        property: "maxScore",
        bronze: {
            value: 9000,
        },
        silver: {
            value: 22000,
        },
        gold: {
            value: 27000,
        },
        capiEndHook: {
            sessionGameMode: ["gymkhana"],
            tuningGroup: ["performance"]
        }
    },
    {
        type: "gymkhanaBasicTuning",
        group: "gymkhana",
        property: "maxScore",
        bronze: {
            value: 8000,
        },
        silver: {
            value: 21000,
        },
        gold: {
            value: 26000,
        },
        capiEndHook: {
            sessionGameMode: ["gymkhana"],
            tuningGroup: ["basic"]
        }
    },
    {
        type: "gymkhanaIceTrackCondition",
        group: "gymkhana",
        property: "maxScore",
        bronze: {
            value: 7000,
        },
        silver: {
            value: 16000,
        },
        gold: {
            value: 20000,
        },
        capiEndHook: {
            sessionGameMode: ["gymkhana"],
            trackCondition: ["drift_ice"]
        }
    },
    {
        type: "gymkhanaGravelTrackCondition",
        group: "gymkhana",
        property: "maxScore",
        bronze: {
            value: 8000,
        },
        silver: {
            value: 20000,
        },
        gold: {
            value: 25000,
        },
        capiEndHook: {
            sessionGameMode: ["gymkhana"],
            trackCondition: ["drift_dirt"]
        }
    },
    {
        type: "gymkhanaWetTrackCondition",
        group: "gymkhana",
        property: "maxScore",
        bronze: {
            value: 9000,
        },
        silver: {
            value: 22000,
        },
        gold: {
            value: 27000,
        },
        capiEndHook: {
            sessionGameMode: ["gymkhana"],
            trackCondition: ["drift_asphalt_wet"]
        }
    }
]

import * as React from "react";
import {AchievementLevelType} from "../../../../../../shared/types/AchievementLevelType";

/******************************************************************
 * AchievementItemOutlines
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementItemOutlines(props: {
    level: AchievementLevelType
}) {
    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showOuterOutline(): boolean {
        switch (props.level) {
            case "gold":
                return true
            default:
                return false
        }
    }

    function showMiddleOutline(): boolean {
        switch (props.level) {
            case "silver":
            case "gold":
                return true
            default:
                return false
        }
    }

    function showInnerOutline(): boolean {
        switch (props.level) {
            case "bronze":
            case "silver":
            case "gold":
                return true
            default:
                return false
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {showInnerOutline() &&
            <path
                d="M53.3446 8.83316C59.6873 5.17118 67.5018 5.17119 73.8446 8.83316L111 30.285C117.343 33.947 121.25 40.7146 121.25 48.0385V90.9422C121.25 98.2661 117.343 105.034 111 108.696L73.8446 130.147C67.5018 133.809 59.6873 133.809 53.3446 130.147L16.1889 108.696C9.84619 105.034 5.93891 98.2661 5.93891 90.9421V48.0385C5.93891 40.7146 9.84619 33.947 16.1889 30.285L53.3446 8.83316Z"
                stroke="#E0C49B"/>}
        {showMiddleOutline() &&
            <path
                d="M51.8446 6.79757C59.1155 2.5997 68.0736 2.5997 75.3446 6.79757L112.013 27.9681C119.284 32.166 123.763 39.924 123.763 48.3197V90.6609C123.763 99.0566 119.284 106.815 112.013 111.012L75.3446 132.183C68.0736 136.381 59.1155 136.381 51.8446 132.183L15.176 111.013C7.90512 106.815 3.42604 99.0566 3.42604 90.6609V48.3197C3.42604 39.924 7.90511 32.166 15.176 27.9682L51.8446 6.79757Z"
                stroke="#E0C49B"/>}
        {showOuterOutline() &&
            <path
                d="M50.8446 4.49938C58.7343 -0.055759 68.4548 -0.0557635 76.3446 4.49937L113.503 25.953C121.393 30.5082 126.253 38.9264 126.253 48.0367V90.944C126.253 100.054 121.393 108.472 113.503 113.028L76.3446 134.481C68.4548 139.036 58.7343 139.036 50.8446 134.481L13.6857 113.028C5.79602 108.472 0.935741 100.054 0.935741 90.944V48.0367C0.935741 38.9264 5.79601 30.5082 13.6857 25.953L50.8446 4.49938Z"
                stroke="#E0C49B"/>}
    </>

}

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {IAchievementData} from "../../../../../shared/models/IAchievementData";
import {AchievementRewardAnimation} from "../../../ui/achievements/reward/AchievementRewardAnimation";
import {AchievementConfetti} from "../../../ui/confetti/AchievementConfetti";
import {AchievementQuery} from "../../../../../shared/achievements/AchievementQuery";
import {AchievementLevelType} from "../../../../../shared/types/AchievementLevelType";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {gsap} from "gsap";

/******************************************************************
 * AchievementRewardsModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AchievementRewardsModal() {

    /* ----------------------------------------------------------------
 	 * REFs
 	 * --------------------------------------------------------------*/

    const contentRef = useRef<HTMLDivElement>()
    const autoSwitcherRef = useRef<number>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [achievements, setAchievements] = useState<IAchievementData[]>(state.showAchievementRewards.getValue())
    const [currentAchievement, setCurrentAchievement] = useState<IAchievementData>(state.showAchievementRewards.getValue()[0])
    const [isSwitching, setIsSwitching] = useState(false)
    const [refreshKey, setRefreshKey] = useState<number>(1)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        state.showAchievementRewards.onChangeSignal.add(updateData)
        return () => {
            state.showAchievementRewards.onChangeSignal.remove(updateData)
        }
    }, [])

    useEffect(() => {
        if (!isSwitching) {
            startAutoSwitcher()
        }
        return () => clearAutoSwitcher()
    }, [isSwitching])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateData() {
        const value = state.showAchievementRewards.getValue()
        setRefreshKey(value => value + 1)
        if (!value || value.length == 0) {
            return
        }
        setAchievements(value)
    }

    function startAutoSwitcher() {
        clearAutoSwitcher()
        autoSwitcherRef.current = window.setInterval(() => {
            showNextAchievement()
        }, 12000)
    }

    function clearAutoSwitcher() {
        if (autoSwitcherRef.current) {
            clearInterval(autoSwitcherRef.current)
        }
    }

    function close() {
        state.showAchievementRewards.setValue(null)
    }

    function confettiStyle(): AchievementLevelType {
        return AchievementQuery.computeLevelAndProgress(
            currentAchievement.type,
            currentAchievement.value
        ).type
    }

    function showNextAchievement() {
        clearAutoSwitcher();
        if (!hasNextAchievement()) {
            close()
            return
        }
        setIsSwitching(true)
        const index = achievements.indexOf(currentAchievement)
        const nextIndex = index + 1 >= achievements.length ? 0 : index + 1
        gsap.to(contentRef.current, {
            x: -nextIndex * 100 + "%",
            duration: 0.8,
            ease: "power4.inOut",
            onComplete: () => {
                setCurrentAchievement(achievements[nextIndex])
                setIsSwitching(false)
            }
        })
    }

    function hasNextAchievement() {
        return achievements.indexOf(currentAchievement) + 1 < achievements.length
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="max"
            height="max"
            type="frameless"
            closeAction={close}
            className="achievement-rewards-modal">
            <div className="achievement-rewards-modal-content-frame">
                <div
                    ref={contentRef}
                    className="achievement-rewards-modal-content">
                    {achievements.map((achievement, i) =>
                        <AchievementRewardAnimation
                            key={i}
                            data={achievement}
                            show={currentAchievement == achievement}/>)}
                </div>
            </div>
            {refreshKey && achievements?.length > 1 &&
                <div className="achievement-rewards-modal-ui">
                    <IconButton
                        type="next"
                        size="huge"
                        onClick={showNextAchievement}/>
                </div>}
            {!isSwitching &&
                <AchievementConfetti
                    width={"100%"}
                    height={"100%"}
                    style={confettiStyle()}/>}
        </Modal>
    );

}

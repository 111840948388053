/******************************************************************
 * AchievementType
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export const ACHIEVEMENT_TYPES = [
    "distanceTotal",
    "distanceRWD",
    "distanceAWD",
    "distanceFWD",
    "distanceMaxTuning",
    "distancePerformanceTuning",
    "distanceBasicTuning",
    "distanceClubsportTuning",
    "distanceRainTrack",
    "distanceGravelTrack",
    "distanceIceTrack",
    "distanceAsphaltTrack",
    "distanceRally",
    "gymkhanaTotal",
    "gymkhanaMaxTuning",
    "gymkhanaPerformanceTuning",
    "gymkhanaBasicTuning",
    "gymkhanaIceTrackCondition",
    "gymkhanaGravelTrackCondition",
    "gymkhanaWetTrackCondition",
] as const

export type AchievementType = typeof ACHIEVEMENT_TYPES[number];

export function isAchievementType(type: string): boolean {
    return ACHIEVEMENT_TYPES.includes(type as AchievementType)
}

import * as React from "react";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {TracksPage} from "../tracks/TracksPage";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {UserGaragePage} from "./garage/UserGaragePage";
import {UserHomePage} from "./home/UserHomePage";
import {UserDrivingSchoolPage} from "./drivingschool/UserDrivingSchoolPage";
import {UserGroupsPage} from "./groups/UserGroupsPage";
import {UserEventsPage} from "./events/UserEventsPage";
import {UserTrainingPage} from "./training/UserTrainingPage";
import {UserAchievementsPage} from "./achievements/UserAchievementsPage";

/******************************************************************
 * UserMain
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserMain() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {
        showUserHome,
        showUserAchievements,
        showUserDrivingSchool,
        showUserTraining,
        showUserEvents,
        showUserGroups,
        showUserTracks,
        showUserGarage} = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="user-main" alignItems="stretch" gap="none">
            {showUserDrivingSchool && <UserDrivingSchoolPage />}
            {showUserTraining && <UserTrainingPage />}
            {showUserAchievements && <UserAchievementsPage />}
            {showUserHome && <UserHomePage />}
            {showUserGroups && <UserGroupsPage />}
            {showUserEvents && <UserEventsPage />}
            {showUserTracks && <TracksPage />}
            {showUserGarage && <UserGaragePage />}
        </ContentLayout>
    );

}

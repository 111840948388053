import {AchievementType} from "../types/AchievementType";
import {AchievementDefinitions} from "./AchievementDefinitions";
import {AchievementGroupType} from "../types/AchievementGroupType";
import {IAchievementDefinition} from "./IAchievementDefinition";
import {SessionGameModeType} from "../types/SessionGameModeType";
import {IStintData} from "../models/IStintData";
import {DriftRacerQuery} from "../cars/DriftRacerQuery";
import {AchievementLevelType} from "../types/AchievementLevelType";

/******************************************************************
 * AchievementQuery
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AchievementQuery {

    static typesPerGroup(group: AchievementGroupType, ignoreHidden?: boolean): AchievementType[] {
        return AchievementDefinitions
            .filter(definition => definition.group === group)
            .filter(definition => ignoreHidden ? !definition.hidden : true)
            .map(definition => definition.type)
    }

    static definitionByType(type: AchievementType): IAchievementDefinition {
        return AchievementDefinitions
            .find(definition => definition.type === type)
    }

    static matchingCapEndHook(sessionGameMode: SessionGameModeType, stint: IStintData): IAchievementDefinition[] {
        return AchievementDefinitions.filter(achievement => {
            return !!achievement.capiEndHook
                && (achievement.capiEndHook.sessionGameMode?.includes(sessionGameMode) ?? true)
                && (achievement.capiEndHook.trackCondition?.includes(stint.enterData.track_condition) ?? true)
                && (achievement.capiEndHook.trackBundle?.includes(stint.enterData.track_bundle) ?? true)
                && (achievement.capiEndHook.drivetrain
                    ? DriftRacerQuery.isDrivetrain(
                        achievement.capiEndHook.drivetrain,
                        stint.enterData.engine_type,
                        stint.enterData.tuning_type)
                    : true)
                && (achievement.capiEndHook.tuningGroup
                    ? DriftRacerQuery.isTuningGroup(
                        achievement.capiEndHook.tuningGroup,
                        stint.enterData.tuning_type)
                    : true)
        })
    }

    static getNewAchievementReward(oldValue: number, newValue: number, type: AchievementType): AchievementLevelType {
        const definition = AchievementQuery.definitionByType(type)
        if (oldValue < definition.gold.value && newValue >= definition.gold.value) {
            return "gold"
        }
        if (oldValue < definition.bronze.value && newValue >= definition.bronze.value) {
            return "bronze"
        }
        if (oldValue < definition.silver.value && newValue >= definition.silver.value) {
            return "silver"
        }
        return null
    }

    static computeLevelAndProgress(type: AchievementType, value?: number, level?: AchievementLevelType): {
        type: AchievementLevelType
        bronzeProgress: number
        silverProgress: number
        goldProgress: number
    } {
        const definition = this.definitionByType(type)
        value = value ?? 0
        if (level == "gold" || value >= definition.gold.value) {
            return {
                type: "gold",
                bronzeProgress: 1,
                silverProgress: 1,
                goldProgress: 1
            }
        }
        if (level == "silver" || value >= definition.silver.value) {
            let goldProgress = (value - definition.silver.value) / (definition.gold.value - definition.silver.value)
            if (goldProgress < 0) {
                goldProgress = 0
            }
            return {
                type: "silver",
                bronzeProgress: 1,
                silverProgress: 1,
                goldProgress: goldProgress
            }
        }
        if (level == "bronze" || value >= definition.bronze.value) {
            let silverProgress = (value - definition.bronze.value) / (definition.silver.value - definition.bronze.value)
            if (silverProgress < 0) {
                silverProgress = 0
            }
            return {
                type: "bronze",
                bronzeProgress: 1,
                silverProgress: silverProgress,
                goldProgress: 0
            }
        }
        return {
            type: "none",
            bronzeProgress: 1 - (definition.bronze.value - value) / definition.bronze.value,
            silverProgress: 0,
            goldProgress: 0
        }
    }

}
